export const formFields2 = [
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      className: 'col-md-3',
      validation: { required: 'Email is required' },
    },
  ];
  
export const customBtnStyle = {
    height: '40px',
    width: '100px',
    backgroundColor: '#4E98F3',
    border: 'none',
    outline: 'none',
    marginTop: '18px',
    borderRadius: '7px',
    color: '#fff',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    cursor: 'pointer',
  };