import { useEffect, useState } from 'react';
import jsPDF from 'jspdf';

const HandleGeneratePdf = ({ gotPdf, blobPdf, setGotPdf, setBlobPdf, data, title, page, ref, setDisable }) => {
  const [pdfs,setPdfs] = useState([])
  const [counter, setCounter] = useState(0);
  
  const currentPage = (data !==null && data.length >= 130) ? Math.ceil(data.length / 130) : 1
  useEffect(() => {
 
    const generatePdf = () =>{
     
        // Update the document title using the browser API
    setGotPdf(true);
    console.log("neno",counter, currentPage) 
    //setGotPdf(true)
    if (gotPdf === false || counter >= currentPage) {
      setDisable(true)
      return null;
    }
    let tables = document.getElementsByClassName('pdf_table');
    let k = document.querySelector('.pdf_table .pagination_table');
    if(k !==null){
    k.style.display = 'none';
    }
    
    
    for (let i in tables) {
      let table = tables[i];

      try {
        console.log(table)
        table.style.display = 'block';
        
        if(k !==null){
          k.style.display = 'none';
          }
        console.log(table)
      } catch (err) { 
        console.log(err)
        continue;
      }

      table.style.width = '1200px';
      const doc = new jsPDF({
        format: 'a2',
        unit: 'pt',
      });
      doc.setFont('Inter-Regular', 'normal');

      doc.html(table, {
        x: 0,
        y: 0,
        autoPaging: 'text',
        
        callback(doc) {
          table.style.width = '100%';

          let limit = table.offsetHeight / 1000;

          if (limit === 0) {
            limit = 1;
          }

          let pageCount = doc.internal.getNumberOfPages();
          for (let i = pageCount; i > limit + 1; i--) {
            doc.deletePage(i);
          }
          let blob = doc.output('blob');

          if (gotPdf == true) {
            setGotPdf(false);
            pdfs.push(blob);
            console.log("---->>>>")
            setBlobPdf([pdfs]);
            //setBlobPdf([blob])


            if( document.querySelector('#next_click')!==null){
              document.querySelector('#next_click').click();
              console.log("h...1",pdfs)
            }else{
              console.log("h...",pdfs)
            }

            table.style.display = 'none';
          }
        },
      });
    
    
    }  setCounter((prevCounter) => prevCounter + 1);
  // Generate PDF after 3 seconds
  
    }
    const timerId = setTimeout(()=>{generatePdf();console.log("hello")}, 3000);

  // Cleanup the timer to avoid memory leaks

  return () => fun(timerId);
  
  }, [data, counter, currentPage]);

  const fun = (timerId) =>{
    let val = counter

    
    clearTimeout(timerId);
  }
  return null;
};


export default HandleGeneratePdf;