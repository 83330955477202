import React from "react";
import { Form, Table } from "react-bootstrap";
import tableStyle from "../assets/css/table.module.css";
import { mockData } from "../Pages/Constants/mockdata";
import { Link } from "react-router-dom";
import Skeletonloader from "../components/skeletonloader/Skeletonloader";

const CommonSkeletonTable = ({ currentPage, props }) => {
  const color = {
    edit: tableStyle.primary,
    delete: tableStyle.danger,
    prime: tableStyle.primary,
  };

  const CapitalFirst = (text) => {
    text = text[0].toUpperCase() + text.slice(1);
    return text;
  };
  return (
   <>
    <Table
      responsive
      ref={props.componentRef}
      className={`p-3 table pdfC table-hover check_${currentPage} ${tableStyle.head}`}
    >
      <thead style={{ backgroundColor: "#47ADFF", color: "white" }}>
        <tr>
          {props.checkbox === true && (
            <th>
              {" "}
              {props.checkbox === true && (
                <p className="mb-3" style={{ textAlign: "center" }}>
                  Select all
                  <Form.Check
                    style={{
                      marginTop: 4,
                    }}
                    type="checkbox"
                    onChange={(e) => {
                      props.topCheckBox(e);
                    }}
                    checked={props?.allcheckboxes?.length > 0 ? true : false}
                  ></Form.Check>
                </p>
              )}
            </th>
          )}
          {Object.keys(props.title).map((title,index) => {
            return (
              <th key={index} style={props.tdCss !== undefined ? { padding: "4px" } : {}}>
                {props.title[title][2] !== undefined &&
                  props.title[title][2] !== "none" ? (
                  <d
                    iv
                    className="row"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="col-9">
                      <p
                        className="mb-3"
                        style={
                          props.tdCss !== undefined
                            ? { ...props.tdCss, textAlign: "center" }
                            : { textAlign: "center" }
                        }
                      >
                        {title}
                      </p>
                    </div>
                    <div className="col-3">
                      {props.title[title][2] !== undefined &&
                        props.title[title][2] !== "none" && (
                          <img
                            onClick={() => {
                              asc_desc(props.title[title][2], title);
                            }}
                            className={`mx-3 ${tableStyle.asc_desc}`}
                            style={{
                              width: "10px",
                              float: "right",
                              marginBottom: "14px",
                            }}
                            src={require("../assets/images/up_down.png")}
                            alt="Up_Down"
                          />
                        )}
                    </div>
                  </d>
                ) : (
                  <p
                    className="mb-3"
                    style={
                      props.PCss !== undefined
                        ? { ...props.PCss, textAlign: "center" }
                        : { textAlign: "center" }
                    }
                  >
                    {title}
                  </p>
                )}
                {props.input &&
                  (props.title[title][1] === "select" ? (
                    <Skeletonloader variant={"text"}
                      width={"100%"}
                      height={37} />
                  ) : props.title[title][1] === "date" ? (
                    <Skeletonloader variant={"text"}
                      width={"100%"}
                      height={37} />
                  ) : props.title[title][1] === "none" ? (
                    <Skeletonloader variant={"text"}
                      width={"100%"}
                      height={37} />
                  ) : (
                    <Skeletonloader variant={"text"}
                      width={"100%"}
                      height={37} />
                  ))}
              </th>
            );
          })}

          {props.print !== undefined && props.print && (
            <th>
             <Skeletonloader variant={"text"} width={"70px"} height={37} />
            </th>
          )}
          {props.extra !== undefined &&
            props.extra !== "none" &&
            Object.keys(props.extra).map((ext, index) => {
              return (
                <th key={index}>
                  <p className="mb-3">{ext}</p>
                  <Form.Control
                    className={`${props.tdCss === undefined ? tableStyle.unset : ""
                      }`}
                    type="text"
                    style={{ visibility: "hidden" }}
                  />
                </th>
              );
            })}

          {props.button !== undefined &&
            Object.keys(props.button).map((data) => {
              return (
                <th></th>
              );
            })}
        </tr>
      </thead>
      {mockData?.length > 0 && (
        <tbody style={{ textAlign: "center" }}>
          {Array.from({ length:props.skeletonrows ? props.skeletonrows : mockData.length +3 }, (_, index) => (
          <tr key={index}>
            {Object.keys(props.title).map((key) => (
              props.title[key][0] !== "id" && (
                <td key={props.title[key][0]} id={props.title[key][0]}>
                  <Skeletonloader variant={"text"} width={"100%"} height={37} />
                </td>
              )
            ))}
            {props.button !== undefined && Object.keys(props.button).map((button, i) => (
              <td key={i}>
                {props.button[button] !== "none" ? (
                  <Skeletonloader variant={"text"} width={"100px"} height={37} />
                ) : (
                  <Skeletonloader variant={"text"} width={"100px"} height={37} />
                )}
              </td>
            ))}
          </tr>
        ))}

        </tbody>
      )}
    </Table>
    <div style={{width:"100%", display:"flex",justifyContent:'end'}}>
      <Skeletonloader variant={"rectangular"} borderRadius={8} width={"300px"} height={30}/>
    </div>
   </>
  );
};

export default CommonSkeletonTable;
