export const mockData = [
    {
      booking: {
        id: 1,
        guest: { first_name: 'John', last_name: 'Doe' },
        room: ['101', '102'],
        status: 'Confirmed' // Example status
      },
      id: 101 // Example ID
    },
    {
      booking: {
        id: 2,
        guest: { first_name: 'Jane', last_name: 'Smith' },
        room: ['201'],
        status: 'Unconfirmed' // Example status
      },
      id: 102 // Example ID
    }
  ];
  

  