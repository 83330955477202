import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import '../../assets/css/redasterick.module.css';
import Css from  '../../assets/css/redasterick.module.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';
import i from '../../assets/images/i.png';
import "react-phone-input-2/lib/style.css";

const ReusableHookForm = ({
  formFields,
  onSubmit,
  customLabelStyle,
  customBtnStyle,
  buttonMainDiv,
  buttonText,
  formReset,
  togglePasswordVisibility,
  showOldPassWord,
  showNewPassWord,
  isPersistentForm,
  handleFieldChange,
  getPersistentData,
  info,
  infoComponentData
}) => {
  const buttonStyle = {
    width: '260px',
    height: '56px',
    color: '#ffffff',
    backgroundColor: '#1AB64F',
    border: 'none',
    borderRadius: '5px',
    fontSize: '20px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontFamily: 'inter',
    ...customBtnStyle,
  };

  const labelStyle = {
    fontWeight: '500',
    fontFamily: 'inter',
    ...customLabelStyle,
  };

  const phoneInputStyles = {
    width: '100%',
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const submitHandler = (data) => {
    onSubmit(data);
    if (formReset === false) {
    } else {
      reset();
    }
  };


  //get persistent data from session storage
    useEffect(() => {
      if(getPersistentData){
        getPersistentData(setValue);
      }
    }, [])
  

  const handlePhoneNumberChange = (value,code) => {
    setValue('phone_number', value); 
    setValue('countrycode', code);// Update the phone number field value in the form
  };

  return (
    <div>
      {
        isPersistentForm ? (
          <form className='row' onSubmit={handleSubmit(submitHandler)}>
          {formFields.map((field, index) => {
            const { label, name, type, placeholder, options, className, validation, defaultValue, readOnly, newInfo } = field;
  
            return (
              <div className={`${className} mb-4`} key={index}>
                <label htmlFor={name} className='required' style={labelStyle}>
                  {label}
                </label>
  
                {type === 'select' ? (
                  <select
                    name={name}
                    {...register(name, validation)}
                    onChange={(e) => {
                      handleFieldChange(`${name}`, e.target.value)
                    }}
                    className={`form-control ${Css['custom-select']}`}
                    style={{ border: errors[name] ? '1px solid red' : '' }}
                  >
                    {options.map((option, index) => (
                      <option key={index} value={option.value} defaultValue={defaultValue} className='bg-light'>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : type === 'tel' ? (
                  <PhoneInput
                    inputProps={{
                      name: name,
                      ref: { ...register(name, validation) },
                    }}
                    country={'us'}
                    onChange={handlePhoneNumberChange}
                    value={defaultValue}
                    disabled={readOnly}
                    enableSearch={true}
                    inputStyle={phoneInputStyles}
                    style={{ border: errors[name] ? '1px solid red' : '' }}
                  />
                ) : (
                  <>
                    <input
                      className='form-control'
                      type={type}
                      name={name}
                      id={name}
                      placeholder={placeholder}
                      {...register(name, validation)}
                      defaultValue={defaultValue}
                      minLength
                      readOnly={readOnly}
                      onChange={(e) => {
                        handleFieldChange(`${name}`, e.target.value);
                      }}
                      style={{ border: errors[name] ? '1px solid red' : "" }}
                    />
                    <span
                      onClick={() => togglePasswordVisibility(name)}
                      style={{ position: 'relative', top: '-29px', left: '329px', cursor: 'pointer' }}
                    >
                      {name === 'old_password' && (showOldPassWord ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                      {name === 'new_password' && (showNewPassWord ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                    </span>
                  </>
                )}
                <div>{newInfo}</div>
                
                {errors[name] && (
                  <small style={{ color: 'red' }}>
                    {togglePasswordVisibility ? errors[name].message.split(',').map((item) => <li>{item}</li>) : errors[name].message}
                  </small>
                )}
              </div>
            );
          })}
  
          {buttonMainDiv ? (
            <div style={buttonMainDiv}>
              <button type='submit' style={buttonStyle}>
                {buttonText || 'Continue'}
              </button>
            </div>
          ) : (
            <button type='submit' style={buttonStyle}>
              {buttonText || 'Continue'}
            </button>
          )}
        </form>
        ) : (
          <form className='row' onSubmit={handleSubmit(submitHandler)}>
          {formFields.map((field, index) => {
            const { label, name, type, placeholder, options, className, validation, defaultValue, readOnly, newInfo, methodInfo } = field;
  
            return (
              <div className={`${className} mb-4`} key={index}>
                <label htmlFor={name} className='required' style={labelStyle}>
                  {label} {methodInfo && <span><img src={i} title={infoComponentData?.title} style={{marginBottom: '2px'}} alt='info' height='20px' width='20px' /></span>} {info && <img src={infoComponentData?.src} title={infoComponentData?.title} style={{marginBottom: '2px'}} alt='info' height='20px' width='20px' />}
                </label>
  
                {type === 'select' ? (
                  <select
                    name={name}
                    {...register(name, validation)}
                    onChange={(e) => {
                      handleFieldChange(`${name}`, e.target.value)
                    }}
                    className={`form-control ${Css['custom-select']}`}
                    style={{ border: errors[name] ? '1px solid red' : '' }}
                  >
                    {options.map((option, index) => (
                      <option key={index} value={option.value} defaultValue={defaultValue} className='bg-light'>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : type === 'tel' ? (
                  <PhoneInput
                    inputProps={{
                      name: name,
                      ref: { ...register(name, validation) },
                    }}
                    country={'us'}
                    onChange={handlePhoneNumberChange}
                    value={defaultValue}
                    disabled={readOnly}
                    enableSearch={true}
                    inputStyle={phoneInputStyles}
                    style={{ border: errors[name] ? '1px solid red' : '' }}
                  />
                ) : type === 'date' ? (
                <>
                <input
                      className='form-control'
                      type={type}
                      name={name}
                      id={name}
                      placeholder={placeholder}
                      {...register(name, validation)}
                      defaultValue={defaultValue}
                      onKeyDown={(e) => e.preventDefault()}
                      minLength
                      readOnly={readOnly}
                      min={"2000-01-01"}
                      max={"2099-12-31"}
                      style={{ border: errors[name] ? '1px solid red' : '' }}
                    />
                </>
                ) : (
                  <>
                    <input
                      className='form-control'
                      type={type}
                      name={name}
                      id={name}
                      placeholder={placeholder}
                      {...register(name, validation)}
                      defaultValue={defaultValue}
                      minLength
                      readOnly={readOnly}
                      style={{ border: errors[name] ? '1px solid red' : '' }}
                    />
                    <span
                      onClick={() => togglePasswordVisibility(name)}
                      style={{ position: 'relative', top: '-29px', left: '329px', cursor: 'pointer' }}
                    >
                      {name === 'old_password' && (showOldPassWord ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                      {name === 'new_password' && (showNewPassWord ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                    </span>
                  </>
                )}
                <div>{newInfo}</div>
                {errors[name] && (
                  <small style={{ color: 'red' }}>
                    {togglePasswordVisibility ? errors[name].message.split(',').map((item) => <li>{item}</li>) : errors[name].message}
                  </small>
                )}
              </div>
            );
          })}
  
          {buttonMainDiv ? (
            <div style={buttonMainDiv}>
              <button type='submit' style={buttonStyle}>
                {buttonText || 'Continue'}
              </button>
            </div>
          ) : (          
            <button type='submit' style={buttonStyle}>
              {buttonText || 'Continue'}
            </button>
          )}
        </form>
        )
      }
  

    </div>
  );
};

export default ReusableHookForm;
