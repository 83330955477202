import React from 'react'
import Logo from "../assets/images/1.png";

const TablePrint = () => {
    const final_logo = localStorage.getItem('final_logo');

    const styles = {
        hotelInfo: {
            border: '1px solid #ccc',
            padding: '16px',
            margin: '16px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            fontFamily: 'Inter',
           
            
        },
        heading: {
            marginBottom: '20px',
            fontFamily: 'Inter',
            fontSize:25,
        },
        text: {
            margin: '4px 0',
            fontFamily: 'Inter',
         
            fontSize:20,
            marginBottom: '20px',
        },
        normalText: {
            fontWeight: 'normal',
            marginBottom: '8px',
        }
    };
    const addData = localStorage.getItem('address')
    const newAddressDataa = addData && JSON.parse(addData)
    return (
        <div style={styles.hotelInfo}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div >
                        <h1 style={styles.heading}>{newAddressDataa?.name} </h1>
                        <p style={styles.text}><strong>Address:</strong> <span style={styles.normalText}>{newAddressDataa?.address} </span></p>
                    </div>
                    <div>
                        <img
                        alt="Alt content"
                        src={final_logo || Logo}
                        style={{
                        width: "80px",
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent:"center"
                        }}
                       />
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div >
                        <p style={styles.text}><span style={{ fontWeight: 800 }}>Email:</span>{newAddressDataa?.email_address}</p>
                    </div>
                    <div>
                        <p style={styles.text}><span style={{ fontWeight: 800 }}>Website:</span>{newAddressDataa?.website_name}</p>
                    </div>
                    <div>
                        <p style={styles.text}><span style={{ fontWeight: 800 }}>Contact No:</span>{newAddressDataa?.phone_number}</p>
                    </div>
                </div>
               
            
        </div>
    )
}

export default TablePrint
