import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components';

const ViewButton = (props) => {
    return (
        <div>
            {props.single ?    <StyledWrapper  color={props.color} background={props.background} >
      <button style={{float:props.float}} onClick={props.onClick} disabled={props.disabled} id={props.id} data-text={props.text} className="Btn">
      
      </button>
    </StyledWrapper>:  <Button
                    disabled={props.disabled}
                 onClick={props.onClick}
                id={props.id}
                className="reservationdottedline2  viewallbutton"
                style={{
                    float: "right",
                    marginTop:props.marginTop,
                    width:props.width,
                    zIndex:0
                  

                }}
            >
                {props.text}
            </Button>}
          
        </div>
    )
}
const StyledWrapper = styled.div`
  .Btn {
    width: 160px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right,rgba(71, 173, 255, 1),rgba(71, 173, 255, 1),rgba(71, 173, 255, 1),#0747A6,#0747A6,#0747A6);
    background-size: 250%;
    background-position: left;
   
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
  }

  .Btn::before {
    position: absolute;
     content: attr(data-text);
    color: ${(props) => props.color || 'white'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: ${(props) => props.background || 'rgba(71, 173, 255, 1)'};
    background-size: 200%;
  }

  .Btn:hover {
    background-position: right;
    transition-duration: 1s;
  }

  .Btn:hover::before {
    background-position: right;
    transition-duration: 1s;
  }

  .Btn:active {
    transform: scale(0.95);
  }`;


export default ViewButton