import mdilake from "../../assets/images/mdi--lake.png"
import beds from "../../assets/images/material-symbols--bed-outline-rounded.png"
import tv from "../../assets/images/material-symbols--tv-outline.png"
import desk from "../../assets/images/material-symbols--desk.png"
import ipad from "../../assets/images/icon-park-outline--ipad.png"
import lap from "../../assets/images/ic--baseline-laptop.png"
export const facilityImages = {
    "Sea View": mdilake,
    "Extra long beds (> 2 meter)":beds ,
    "Sofa bed":beds ,
    "TV":tv,
    "Sofa":beds ,
    'Desk':desk,
    'iPad':ipad,
    'Video':tv,
    'Laptop':lap
  };