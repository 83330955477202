import React, { useState, useRef, useEffect, Component } from "react";
import { Form, Table, OverlayTrigger, Popover } from "react-bootstrap";
import tableStyle from "../../assets/css/table.module.css";
import dots from "../../assets/images/dots.png";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import ConvertDateTime from "../Common_Fun/ConvertDateTime";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Button from "react-bootstrap/Button";
import ConvertLatter from "../Common_Fun/ConvertLatter";
import roomMove from "../../assets/css/roomdetails.module.css";
import { mockData } from "./mockdata";
import CommonSkeletonTable from "../../common/commonSkeletonTable";
import "../../assets/css/tableprint.css"
import TablePrint from "../../common/TablePrint";
import { convertDateToTimeZone } from "../Dashboard/utils/timeUtils";
import moment from "moment-timezone";
import PrintButton from "../../common/Animations/PrintButton";
/* need to read instructions before use this page
data = {table data} All backend data that you want to show
title = {head:[backend key, input type]}
  - head mean th,  table head like Name and Start Date and other
  - backend key - if you want to show the start date then its key show in the backend table as {startDay: "2023-02-15"} 
    so, the backend key is startDay
  - input type - if you want to filter option in the table then input type will help you add the input type like text, number
    if you don't want to add a filter for some option you can write "none" instead of the input type
input = if you don't want to add a filter for all options then use input={false} if not then you can use input={true}
button = if the designer wants to show the edit and delete button in the table then this prop will help it pass props like
- {{"edit": "none"}} = edit means edit button and "none" mean call function when the user clicks the button and the designer will pass the button in buttonType
- {{"edit":"/room_view/"}} = when the designer passes the value as /room_view/, it's means this page will reach this location /room_view/{id}
buttonType = if the designer wants to call a function after clicking the button then button type will help to pass the function
permissions = pass backend permission to function
page = page name
select_val = {"head": "value"} if the page has a select button then pass the select value with a title name like {"Room Type":["king", "single"]}
- note:- only pass values as an array, not a dictionary like {"id": "3","all_room_type: "king"} just pass like ["king"]
extra = if the user wants to show extra data in the table which's not in the backend so, the user can pass {"extra": data}  
- note:- the user only pass the data as {"id": "value"}
trSelect = {"head":[value]} if the designer wants to add a select button in td section then trSelect option will help to add a button in td section
thSelectFun = {function} if the designer wants to call a function after clicking on td select button then thSelect fun will help the designer to call fun
onChange(()⇒{props.thSelectFun()})
addTime = if the designer wants to add time with a date in the table then add time will help the user to pass addTime like
- {'Check-In':["checkin_date addTime checkin_time ", 'date']}
Check-In = title and checkin_date mean backend data key addTime specific character checkin_time is another key for the time
*/

function Tables(props) {
  const color = {
    edit: tableStyle.primary,
    delete: tableStyle.danger,
    prime: tableStyle.primary,
  };
  const [searchData, setSearchData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [newTest, setNewTest] = useState([]);
  const [dateCon, setDate] = useState({});
  const [condition, setCondition] = useState(true);
  const [allData, setAllData] = useState({});
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsperpage] = useState(
    props.limit !== undefined ? props.limit : 10
  );
  console.log(condition , searchData , pageData , pageCount, "limit");
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const allArr = [
    "Start Date",
    "Check-In Date",
    "End Date",
    "Check-Out Date",
    "Check-In",
    "Check-Out",
  ];
  const [paginationShow, setPaginationShow] = useState(true);
  const [KeyValue, setKeyValue] = useState({});
  const [reval, setReVal] = useState([]);
  const [asc, setAsc] = useState(true);
  const [warningMsg, setWarningMsg] = useState("No data Available");
  const componentRef = useRef();
  const windowRef = useRef(window);
  const validSymbol = ["<", ">", "==", ">=", "<="];

  useEffect(() => {
    // (props.pagePer !== undefined && props.pagePer !== false ) ?  props.pagePer :10

    if (props.loading2) {

    }
    else {
      if (props.warningMsg !== undefined) {
        setWarningMsg(props.warningMsg);
      }
      paginationData();
      let keys_add = {};
      let date = {};
      let title;
      let keyvalue = {};
      let Start = [];
      let End = [];
      let reValue = {};
      Object.keys(props.title).map((newVal) => {
        if (newVal === "Start Date") {
          Start.push(newVal);
          reValue[props.title[newVal][0]] = newVal;
        } else if (newVal === "Check-In Date" || newVal === "Check-In") {
          Start.push(newVal);
          reValue[props.title[newVal][0]] = newVal;
        } else if (newVal === "End Date") {
          End.push(newVal);
          reValue[props.title[newVal][0]] = newVal;
        } else if (newVal === "Check-Out Date" || newVal === "Check-Out") {
          End.push(newVal);
          reValue[props.title[newVal][0]] = newVal;
        }

        title = props.title[newVal][0];

        keys_add[title] = "";
        date[title] = false;
        keyvalue[props.title[newVal][0]] = props.title[newVal][1];
      });
      setReVal(reValue);
      setStartDate(Start);
      setEndDate(End);
      setKeyValue(keyvalue);
      setAllData(keys_add);
      setDate(date);
    }
  }, [props.loading2]);
  const keyDown = (event) => {
    if (
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key == "e" ||
      event.key == "E"
    ) {
      // Prevent the default behavior of arrow keys
      event.preventDefault();
    }
  };

  function createSafeFunction(title) {
    if (!title) return ""; // If title is empty, return empty string

    // Convert value["assign_by"]["user"]["username"] to value?.["assign_by"]?.["user"]?.["username"]
    const safeTitle = title.replace(/\["(.*?)"\]/g, '?.["$1"]'); 

    return new Function("value", `return ${safeTitle} ?? "";`);
}

  const MouseW = (event) => {
    event.currentTarget.blur();
  };

  const convert = (value, title) => {
    let date = false;
    let id = title[0];

    if (title[1] === "date") {
     
      date = true;
    }

    let newTitle = title;
    title = title[0];

    let last_name = null;
    let conLast;
    let time = null;

    if (title.includes("first_name")) {
      last_name = title.replace("first_name", "last_name");
    }

    if (props.addTime !== undefined) {
      if (props.addTime[title] !== undefined) {
        time = props.addTime[title];

        if (time.includes("[")) {
          const timeFun = new Function("value", `return ${time}`);
          time = timeFun(value);
        } else {
          time = value[time];
        }

        if (time !== null && time !== undefined) {
          console.log("giek",time)
          time = ConvertDateTime(time, "time");
        }
      }
    }

    if (title.includes("[")) {
      console.log("abc>1>",title)
      // const safeTitle = title ? `value?.${title}` : `""`;
      const func = createSafeFunction(title)
      console.log("abc>>",func,title)
      let values = func(value);

      if (last_name !== null) {
        const lastName = new Function("value", `return ${last_name}`);

        conLast = lastName(value);
        value = `${values} ${conLast}`;
      } else {
        value = values;
        if (Array.isArray(value)) {
          value = value.join(", ");
        }
      }
    } else {
      if (last_name !== null) {
        value = `${value[title]}  ${(value = value[last_name])}`;
      } else {
        value = value[title];
        if (Array.isArray(value)) {
          value = value.join(", ");
        }
      }
    }

    if (date === true) {
       value = convertDateToTimeZone(value, props.timeZonename,'MM-DD-YYYY');
       
      
    }

    if (title.includes("Role")) {
      value = String(value);
    }
    console.log(title,'dkdkdkdk')
    if (time !== null) {
      value = `${value} \n ${time}`; 
    }
    if(title==='assign_time'){
      value = moment(value,'hh:mm:ss A').format('hh:mm:ss A')
     
    }
    

    if (newTitle[1] === "number") {
      if (Number(value) === value && value % 1 !== 0) value = value.toFixed(2);
    }
    if (typeof value === "boolean") {
      value = value.toString();
      value = ConvertLatter(value, "first");
    }

    if (newTitle[1] === "text" || newTitle[1] === "select") {
      if (typeof value === "string") {
        value = ConvertLatter(value, "first");
      }
    }
    return value;
  };

  const paginationData = (
    currentPage = 0,
    getData = null,
    itemspage = null,
    filter = null,
  ) => {
    let startIndex;
    let endIndex;

    if (itemspage !== null) {
      startIndex = currentPage * itemspage;
      endIndex = startIndex + itemspage;
    } else {
      startIndex = currentPage * itemsPerPage;
      endIndex = startIndex + itemsPerPage;
    }

    let sliceData;
    if (condition) {
      sliceData = props.data;
    } else {

      sliceData = getData;

    }
    if (sliceData?.length > itemsPerPage) {
      setPaginationShow(true)
    }
    if (filter !== null) {
      sliceData = getData;
    }

    let length = sliceData ? Object.keys(sliceData).length : 0;
    let cal = Math.ceil(length / itemsPerPage);

    setPageCount(cal);

    const currentItems = Array.isArray(sliceData) ? sliceData.slice(startIndex, endIndex) : props.data;

    if (filter === null) {
      if (condition) {
        setTableData(currentItems);
      } else {
        setNewTest(currentItems);
      }
    } else {
      setNewTest(currentItems);
    }

  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    paginationData(selectedPage, pageData);
  };

  const convertOperator = (symbol) => {
    const operatorMap = {
      "<": (a, b) => a < b,
      ">": (a, b) => a > b,
      "==": (a, b) => a === b,
      ">=": (a, b) => a >= b,
      "<=": (a, b) => a <= b,
    };
  };

  // const conditions = (data) =>{
  //   let conditionsVal = props.trSelectCon[data]
  //   let comparisonResult;
  //   if(conditionsVal.length === 3){
  //     const [left, condition, right] = props.trSelectCon[data]
  //     if(validSymbol.includes(condition)){
  //       if( )
  //     }

  //   }

  // }

  const myFun = (title, value, date = false, head = null) => {
    setPaginationShow(false);
    setCondition(false);
    allData[title] = value;
    dateCon[title] = date;

    let searchData = props.data;

    let convertVal;
    let last_name = null;
    let replace;
    let convert2;
    const FixData = props.title;

    Object.keys(allData).map((title) => {
      value = allData[title];

      searchData = searchData.filter((val, index, o) => {
        if (title.includes("[")) {
          if (title.includes("first_name")) {
            last_name = title.replace("first_name", "last_name");
            replace = title.replace("value[", "val[");
            last_name = last_name.replace("value[", "val[");
            convert2 = eval(last_name);
            convertVal = eval(replace);
            if (convertVal !== undefined) {
              convertVal = convertVal.toString();
            }
            if (convert2 !== undefined) {
              convert2 = convert2.toString();
            }
            convertVal = `${convertVal} ${convert2}`;
          } else {
            replace = title.replace("value[", "val[");
            convertVal = eval(replace);
            if (convertVal !== undefined) {
              convertVal = convertVal.toString();
            }
          }
        } else {
          if (title.includes("first_name")) {
            last_name = title.replace("first_name", "last_name");
            convert2 = val[last_name];
            if (convert2 !== undefined) {
              convert2 = convert2.toString();
            }
            convertVal = `${val[title]} ${convert2}`;
          }
          convertVal = val[title];
          if (convertVal !== undefined && convertVal !== null) {
            convertVal = convertVal.toString();
          }
        }

        if (convertVal !== undefined && convertVal !== null) {
          convertVal = convertVal.toLowerCase();
        } else {
          return val;
        }
        if (typeof value === "string") {
          value = value.toLowerCase();
        }

        if (
          KeyValue[title] !== "number" &&
          KeyValue[title] !== "date" &&
          KeyValue[title] !== "select"
        ) {
          if (convertVal.includes(value)) {
            return val;
          }
        }

        if (KeyValue[title] === "select") {
          if (convertVal === value) {
            return val;
          }
        }

        if (KeyValue[title] === "number") {
          let convert;
          if (convertVal % 1 !== 0) {
            convert = Number(convertVal).toFixed(2);
          } else {
            convert = convertVal;
          }
          if (convert.includes(value)) {
            return val;
          }
        }

        if (KeyValue[title] === "number" && KeyValue[title] !== "date") {
          if (convertVal.includes(",")) {
            let stringValue = convertVal;
            let stringArray = stringValue.split(","); // Split the string into an array based on the comma delimiter

            let numberArray = stringArray.map(function (item) {
              return parseInt(item); // Convert each string element to an integer
            });

            if (numberArray.includes(parseInt(value))) {
              return val;
            }
          }
          // if (convertVal === value) {
          //   return val
          // }
        }

        console.log("keyValue",KeyValue[title]);
        
        if (KeyValue[title] === "date") {
         
          convertVal = convertDateToTimeZone(convertVal, props.timeZonename,'MM-DD-YYYY');
         
          


          if (value !== "") {
          
            value = convertDateToTimeZone(allData[title], props.timeZonename,'MM-DD-YYYY');
           
            if (allArr.includes(reval[title])) {
              console.log(value,convertVal, 'else')
              if (
                props.title[
                endDate.includes("End Date")
                  ? "End Date"
                  : endDate.includes("Check-Out")
                    ? "Check-Out"
                    : "Check-Out Date"
                ][0] === title
              ) {
                if (value >= convertVal) {
                 
                  return val;
                }
              }

              if (
                props.title[
                startDate.includes("Start Date")
                  ? "Start Date"
                  : startDate.includes("Check-In")
                    ? "Check-In"
                    : "Check-In Date"
                ][0] === title
              ) {
                if (value <= convertVal) {
                 
                  return val;
                }
              }
            } else {
              if (value === convertVal) {
               
                return val;
              }
            }
          } else {
           
            if (value <= convertVal) {
              return val;
            }
          }
        }

        if (convertVal === undefined) {
          return false;
        }

        if (value === undefined) {
        }

        if (value === "") {
          return val;
        }
      });
    });

    let newSearch = searchData;
    paginationData(0, newSearch, null, true);
    // setTableData(newSearch);
    // setNewTest(newSearch);
    setPageData(newSearch);

    setSearchData({ ...searchData, [`${title}`]: value });

  };

  const asc_desc = (type, title) => {
    let titleData = props.title[title][0];
    let changeData = condition ? props.data : newTest;

    console.log(changeData,condition, "changeData");
    if (asc) {
      let asc_data = changeData.sort((data_a, data_b) => {
        if (type !== "time" && type !== "number") {
          let dataA =
            type === "name" ? data_a[titleData] : new Date(data_a[titleData]);
          let dataB =
            type === "name" ? data_b[titleData] : new Date(data_b[titleData]);

          if (dataA < dataB) {
            return -1;
          }
          if (dataA > dataB) {
            return 1;
          }
          return 0;
        } else {
          let dataA =
            type === "time"
              ? new Date(`01-01-2023T${data_a[titleData]}`)
              : parseInt(data_a[titleData]);
          let dataB =
            type === "time"
              ? new Date(`01-01-2023T${data_b[titleData]}`)
              : parseInt(data_b[titleData]);
          return dataA - dataB;
        }
      });
      setItemsperpage(10);
      paginationData(currentPage, pageData);
      condition ? setPageData(asc_data) : setNewTest(asc_data);

      setAsc(false);
    } else {
     

      let asc_data = changeData.sort((data_a, data_b) => {
        if (type !== "time" && type !== "number") {
          let dataA =
            type === "name" ? data_a[titleData] : new Date(data_a[titleData]);
          let dataB =
            type === "name" ? data_b[titleData] : new Date(data_b[titleData]);

          if (dataA > dataB) {
            return -1;
          }
          if (dataA < dataB) {
            return 1;
          }
          return 0;
        } else {
          let dataA =
            type === "time"
              ? new Date(`01-01-2023T${data_a[titleData]}`)
              : parseInt(data_a[titleData]);
          let dataB =
            type === "time"
              ? new Date(`01-01-2023T${data_b[titleData]}`)
              : parseInt(data_b[titleData]);
          return dataB - dataA;
        }
      });
    
      setItemsperpage(10);
      paginationData(currentPage, pageData);
      condition ? setPageData(asc_data) : setNewTest(asc_data);
      setAsc(true);
    }
  };

  const CapitalFirst = (text) => {
    text = text[0].toUpperCase() + text.slice(1);
    return text;
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
  });


  const handlePrint = () => {
    if(pageCount > 0 && newTest.length > 0){
      setItemsperpage(pageData?.length);
    paginationData(0, pageData, pageData?.length);
    const timeId = setTimeout(() => {
      print();
      setItemsperpage(10);
      paginationData(0, pageData, 10);
    }, 2000);
    return () => clearTimeout(timeId);
    }
    else{
      setItemsperpage(props.data.length);
      paginationData(0, tableData, props.data.length);
      const timeId = setTimeout(() => {
        print();
        setItemsperpage(10);
        paginationData(0, tableData, 10);
      }, 2000);
      return () => clearTimeout(timeId);
    }
    
  };
  console.log(pageData ,tableData, newTest,'pageData')
  const subStr = (text) => {
    if (text !== null && text !== undefined) {
      if (text.length >= 7) {
        // return text.substring(0, 6) + "...";
        return text;
      } else {
        return text;
      }
    } else {
      return text;
    }
  };
  console.log(props,'fkfkfkfk')
  return (
    <>
      {props.loading2 ? '' : <>
        {props?.data?.length <= 0 && (
          <span
            className={`${tableStyle.warningSpan} d-flex align-items-center justify-content-center`}
          >
            <img
              className={tableStyle.warnImg}
              src={require("./../../assets/images/warning.png")}
              alt="warning"
            ></img>
            <p className={tableStyle.warnP}>{warningMsg}</p>
          </span>
        )}
      </>}
      {props.loading2 ? <>

       <CommonSkeletonTable skeletonrows={props.skeletonrows} componentRef={componentRef} props={props}/>
      </> : <>
        {props?.data?.length > 0 && (
          <>
         <div  >

        
          <div ref={componentRef}>
          <div className="print-only" style={{ marginBottom: '20px' }}>
         <TablePrint/>
      </div>
          <Table
              responsive
            
              className={`p-3 table pdfC table-hover check_${currentPage} ${tableStyle.head}`}
            >
              <thead style={{ backgroundColor: "#47ADFF", color: "white" }}>
                <tr>
                  {/* check box  */}
                  {props.checkbox === true && <th> {props.checkbox === true && <p className='mb-3' style={{ textAlign: 'center' }}>

                  Select all
                    <Form.Check
                      style={{
                        marginTop: 4
                      }}
                      type='checkbox'
                      onChange={(e) => { props.topCheckBox(e) }}
                      checked={props.selectClicked} 
                    >

                    </Form.Check>
                  </p>}</th>}


                  {/* props.title keys calls as array */}



                  {Object.keys(props.title).map((title,index) => {
                    return (
                      <th
                      key={index}
                        style={
                          props.tdCss !== undefined ? { padding: "4px" } : {}
                        }
                      >
                        {/* title key name */}
                        {props.title[title][2] !== undefined &&
                          props.title[title][2] !== "none" ? (
                          <div
                            className="row"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="col-9">
                              <p
                                className="mb-3"
                                style={
                                  props.tdCss !== undefined
                                    ? { ...props.tdCss, textAlign: "center" }
                                    : { textAlign: "center" }
                                }
                              >
                                {title}
                              </p>
                            </div>
                            <div style={{border:"1px solid transparent"}} className="col-3">
                              {props.title[title][2] !== undefined &&
                                props.title[title][2] !== "none" && (
                                  <img
                                    onClick={() => {
                                      asc_desc(props.title[title][2], title);
                                    }}
                                    className={`mx-3 ${tableStyle.asc_desc}`}
                                    style={{
                                      width: "10px",
                                      float: "right",
                                      marginBottom: "14px",
                                    }}
                                    src={require("../../assets/images/up_down.png")}
                                    alt="Arrowkeys image"
                                  />
                                )}
                            </div>
                          </div>
                        ) : (
                          <p
                            className="mb-3"
                            style={
                              props.PCss !== undefined
                                ? { ...props.PCss, textAlign: "center" }
                                : { textAlign: "center" }
                            }
                          >
                            {title}
                          </p>
                        )}

                        {/* if props.input is true then the button like input and select button will show */}

                        {props.input &&
                          // all button show according to the given values like text, select and others

                          // myFun is use for filter the data

                          // props.select_val[title] like {Room Type:["king","single"]} => props.select_val[Room Type]
                          // note:- props.select_val key name and title key name keep both the same when you define it

                          (props.title[title][1] === "select" ? (
                            <Form.Select
                              style={props.tdCss !== undefined ? props.tdCss : {}}
                              className={`${props.tdCss === undefined ? tableStyle.unset : ""
                                }`}
                              onChange={(e) => {
                                myFun(props.title[title][0], e.target.value);
                              }}
                              aria-label="Default select example"
                              title={`${title}`}
                            >
                              <option value="">Select One</option>
                              {props.select_val[title].map((option) => {
                                return <option value={option}>{option}</option>;
                              })}
                            </Form.Select>
                          ) : props.title[title][1] === "date" ? (
                            <Form.Control
                              style={props.tdCss !== undefined ? props.tdCss : {}}
                              className={`${props.tdCss === undefined ? tableStyle.unset : ""
                                }`}
                              
                              onChange={(e) => {
                                myFun(
                                  props.title[title][0],
                                  e.target.value,
                                  true,
                                  title
                                );
                              }}
                              type="date"
                              title={`${title}`}
                              id={`${title}`}
                              min={"01-01-2000"}
                              max={"31-12-2099"}
                            />
                          ) : props.title[title][1] === "none" ? (
                            <Form.Control
                              style={
                                props.tdCss !== undefined
                                  ? { ...props.tdCss, visibility: "hidden" }
                                  : {}
                              }
                              className={`${props.tdCss === undefined ? tableStyle.unset : ""
                                }`}
                              type="text"
                            />
                          ) : (
                            props?.title[title][1] === 'amenity' ? <>
                            
                            </>:<Form.Control
                              style={props.tdCss !== undefined ? props.tdCss : {}}
                              className={`${props.tdCss === undefined ? tableStyle.unset : ""
                                }`}
                              onKeyUp={(e) => {
                                myFun(props.title[title][0], e.target.value);
                              }}
                              onKeyDown={(e) => {
                                props.title[title][1] === "number" && keyDown(e);
                              }}
                              onWheel={(e) => {
                                props.title[title][1] === "number" && MouseW(e);
                              }}
                              placeholder={`${title}`}
                              title={`${title}`}
                              type={
                                props.title[title][1] === "text"
                                  ? "text"
                                  : props.title[title][1] === "email"
                                    ? "email"
                                    : "number"
                              }
                              id={`${title}`}
                            />
                          ))}
                      </th>
                    );
                  })}

                  {props.print !== undefined && props.print && (
                    <th>
                      {/* <ReactToPrint trigger={() => { return <button style={{width:65}} id="print" className={tableStyle.print}><img style={{ width: "27%" }} src={require('../../assets/images/Printer.png')} /> Print</button> }}
                content={() => componentRef.current}
                documentTitle='roomservice'
                pagestyle='print'
              /> */}

{/* <ReactToPrint
  trigger={() => (
    <PrintButton disabled={tableData?.length > 0 ? false : true} />
  )}
  content={() => componentRef.current}
  documentTitle="roomservice"
  
  
  
/> */}
                       <PrintButton
                        disabled={searchData === "" ? tableData?.length > 0 ? false : true :pageData?.length > 0 ? false : true}
                        onClick={handlePrint}
                       
                        id="print"
                       
                      /> 
                       
                    </th>
                  )}

                  {/* this will cover extra space for table  */}

                  {props.extra !== undefined &&
                    props.extra !== "none" &&
                    Object.keys(props.extra).map((ext, index) => {
                      return (
                        <th key={index}>
                          <p className="mb-3">{ext}</p>
                          <Form.Control
                            className={`${props.tdCss === undefined ? tableStyle.unset : ""
                              }`}
                            type="text"
                            style={{ visibility: "hidden" }}
                          />
                        </th>
                      );
                    })}

                  {props.button !== undefined &&
                    Object.keys(props.button).map((data,index) => {
                      return (
                        ((props.permissions !== undefined &&
                          props.permissions.includes(data)) ||
                          (data !== "edit" && data !== "delete")) && <th key={index}></th>
                      );
                    })}
                </tr>
              </thead>

              {/* this table only show after the page load and also tableData and newTest mean the backend data 
        condition default value is false but when user filter the data the condition value is true  */}

              {tableData.length > 0 && (
                <tbody style={{ textAlign: "center" }}>
                  {(condition ? tableData : newTest).map((value) => {
                    // id or number not show in table as Data

                    return (
                      <tr
                        key={
                          value["id"] !== undefined
                            ? value["id"]
                            : value["number"]
                        }
                      > {props.checkbox !== undefined && props.checkbox === true && <td >
                        
                        <Form.Check
                          type='checkbox'
                          className={`${tableStyle.unset}`}
                          onChange={(e) => { props.SelectCheckBox(e, value) }}
                          checked={props.allcheckboxes?.find(item => item.id === value.number_id)?.checked}
                          aria-label="Default select example">
                        </Form.Check>
                      </td>}
                        {Object.keys(props.title).map((key) => {
                          return (
                            props.title[key][0] !== "id" && (
                              <>
                                {/* td data show with select box */}
                                {/* if user want to pass tr select value  */}
                                {props.trSelect !== undefined &&
                                  props.trSelect[key] !== undefined ? (
                                  <td
                                    id={props.title[key][0]}
                                    key={props.title[key][0]}
                                  >
                                    <Form.Select
                                      title={convert(value, props.title[key])}
                                      style={
                                        props.tdCss !== undefined
                                          ? props.tdCss
                                          : {}
                                      }
                                      className={`${props.tdCss === undefined
                                        ? tableStyle.unset
                                        : ""
                                        }`}
                                      onChange={(e) => {
                                        props.thSelectFun(e, value);
                                      }}
                                      aria-label="Default select example"
                                    >
                                      {props.trSelect[key].map((option,i) => {
                                        return (
                                          <option
                                            key={i}
                                            value={option}
                                            selected={
                                              convert(value, props.title[key]) ===
                                              option && option
                                            }
                                          >
                                            {option}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>{" "}
                                  </td>
                                ) : // if user want to pass td button or value as button
                                  props.tdBtn !== undefined &&
                                    props.tdBtn[key] !== undefined ? (
                                    <td
                                      id={props.title[key][0]}
                                      key={props.title[key][0]}
                                    >
                                      {" "}
                                      {props.tdBtn[key] !== "none" ? (
                                        <Link
                                          to={`${props.tdBtn[key]}${value["id"] !== undefined
                                            ? value["id"]
                                            : value["number_id"]
                                            }`}
                                          style={
                                            props.tdCssConBtn !== undefined
                                              ? props.tdCssConBtn[0] ===
                                                convert(value, props.title[key])
                                                ? props.CssAppBtn[
                                                props.tdCssConBtn[0]
                                                ]
                                                : props.CssAppBtn[
                                                props.tdCssConBtn[1]
                                                ]
                                              : ""
                                          }
                                          className={`btn ${props.tdCssConBtn === undefined &&
                                            color["prime"]
                                            }`}
                                          type="button"
                                        >
                                          {convert(value, props.title[key])}
                                        </Link>
                                      ) : (
                                        <button
                                          style={
                                            props.tdCssConBtn !== undefined
                                              ? props.tdCssConBtn[0] ===
                                                convert(value, props.title[key])
                                                ? props.CssAppBtn[
                                                props.tdCssConBtn[0]
                                                ]
                                                : props.CssAppBtn[
                                                props.tdCssConBtn[1]
                                                ]
                                              : ""
                                          }
                                          onClick={(e) => {
                                            props.buttonType(e, value);
                                          }}
                                          className={`btn  ${props.tdCssConBtn === undefined &&
                                            color["prime"]
                                            }`}
                                        >
                                          {convert(value, props.title[key])}
                                        </button>
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      style={
                                        props.tdCss !== undefined ? props.tdCss : {}
                                      }
                                      id={props.title[key][0]}
                                      title={
                                        props.tdTitle && props.tdTitle[key]
                                          ? convert(value, props.tdTitle[key])
                                          : convert(value, props.title[key])
                                      }
                                      key={props.title[key][0]}
                                    >
                                      <>
                                        {props.title[key][1] === 'amenity' && <input type="checkbox"/>}
                                        {props.addL !== undefined &&
                                          props.addL[key] && <>{props.addL[key]}</>}
                                        {props.tdCss !== undefined
                                          ? subStr(convert(value, props.title[key]))
                                          : convert(value, props.title[key])}
                                        {props.addR !== undefined &&
                                          props.addR[key] && <>{props.addR[key]}</>}
                                      </>
                                    </td>
                                  )}
                             

                                {/* if designer want to add value as button  */}
                                {/* {props.tdBtn !== undefined  && <td id={props.title[key][0]} key={props.title[key][0]}>{props.tdBtn[key] !==undefined ?  <button  onClick={(e) => { props.buttonType(e, value) }} className={`btn  ${color["prime"]}`}>{convert(value, props.title[key])}</button> : convert(value, props.title[key])}</td>} */}

                                {/* if designer did't add trSelect then this will run also if designer did't add the tdBtn then this will run */}
                                {/* {(props.trSelect === undefined && props.tdBtn === undefined) && <td id={props.title[key][0]} key={props.title[key][0]}>{(convert(value, (props.title[key])))}</td>} */}
                              </>
                            )
                          );
                        })}

                        {props.extra !== undefined &&
                          props.extra !== "none" &&
                          Object.keys(props.extra).map((ext, index) => {
                            return (
                              <td key={index}>{props.extra[ext][value["id"]]}</td>
                            );
                          })}
                        {props.button !== undefined &&
                          Object.keys(props.button).map((button, i) => {
                            return (
                              ((props.permissions !== undefined &&
                                props.permissions.includes(button)) ||
                                (button !== "edit" && button !== "delete")) && (
                                <td key={i}>
                                  {props.button[button] !== "none" ? (
                                    <Link
                                      to={`${props.button[button]}${value["id"] !== undefined
                                        ? value["id"]
                                        : value["number_id"]
                                        }`}
                                      className={`btn ${button !== "edit" || button !== "delete"
                                        ? color["prime"]
                                        : color[button]
                                        }`}
                                      type="button"
                                    >
                                      {CapitalFirst(button)}
                                    </Link>
                                  ) : (
                                    <button
                                    id={button}
                                      style={
                                        props.buttonStyle !== undefined
                                          ? props.buttonStyle
                                          : {}
                                      }
                                      onClick={() => {
                                        props.buttonType(button, value);
                                      }}
                                      className={`btn ${button !== "edit" && button !== "delete"
                                        ? color["prime"]
                                        : color[button]
                                        }`}
                                    >
                                      {CapitalFirst(button)}
                                    </button>
                                  )}{" "}
                                </td>
                              )
                            );
                          })}

                        {props.dotSelect !== undefined && (
                          <td>
                            <OverlayTrigger
                              placement={"left"}
                              trigger="click"
                              rootClose={true}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div style={{ display: "grid" }}>
                                      {Object.keys(props.dotSelect).map(
                                        (button,index) => {
                                          return props.dotSelect[button] !==
                                            "none" ? (
                                            <Link
                                            key={index}
                                              style={{ marginBottom: "5px" }}
                                              to={`${props.button[button]}${value["id"] !== undefined
                                                ? value["id"]
                                                : value["number_id"]
                                                }`}
                                              className={`btn ${color["prime"]}`}
                                              type="button"
                                            >
                                              {CapitalFirst(button)}
                                            </Link>
                                          ) : (
                                            <button
                                              id={button}
                                              style={{ marginBottom: "5px" }}
                                              value={button}
                                              onClick={(e) => {
                                                props.thSelectFun(e, value);
                                              }}
                                              className={`btn ${color["prime"]}`}
                                            >
                                              {CapitalFirst(button)}
                                            </button>
                                          );
                                        }
                                      )}

                                      {/* <button
                      style={{marginBottom:'5px'}}
                      className={`btn ${color['prime']}`}
                            value={option}
                            onClick={(e) => { props.thSelectFun(e, value) }} >{option}
                           </button> })} */}
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button
                                id="threedots"
                                style={{ float: "left", width: "50px" }}
                                className={roomMove.ovBtn}
                              >
                                <img
                                  data-tooltip="dd"
                                  className={roomMove.i}
                                  src={dots}
                                />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        )}

                        {props.print !== undefined &&
                          props.dotSelect === undefined &&
                          props.print && <td></td>}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
         </div>

            {(props.pagePer !== undefined || props.pagePer !== false) &&
              props.data.length >= 10 && (
                <div
                  className={`row ${props.limit !== undefined && "pagination_table"
                    }`}
                >
                  <div className="col"></div>
                  <div className="col">
                    {paginationShow && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            className={`${tableStyle["transplant-button"]}`}
                            disabled={currentPage === 0}
                          >
                            <span className={`${tableStyle["dark-blue"]}`}>
                              {" "}
                              &lt;
                            </span>
                            <span className={`${tableStyle["light-blue"]}`}>
                              &lt;
                            </span>
                            <span className={`${tableStyle.title}`}>
                              Previous{" "}
                            </span>
                          </button>
                        }
                        nextLabel={
                          <button
                            id={props.id !== undefined && `next_click`}
                            className={`${tableStyle["transplant-button"]}`}
                            disabled={currentPage === pageCount - 1}
                          >
                            <span className={`${tableStyle.title}`}>Next </span>
                            <span className={`${tableStyle["light-blue"]}`}>
                              &gt;
                            </span>
                            <span className={`${tableStyle["dark-blue"]}`}>
                              &gt;
                            </span>
                          </button>
                        }
                        className={`${tableStyle["custom-pagination"]}`}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={`${tableStyle["page-num"]}`}
                        pageClassName={`${tableStyle["page-num"]}`}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={`${tableStyle.number}`}
                      />
                    )}
                  </div>
                </div>
              )}
          </>
        )}
      </>}
    </>
  );
}

export default Tables;
