import React, { useEffect, useState } from 'react'
import adminLayout from '../../hoc/adminLayout'
import Tables from '../Constants/Tables'
import ViewButton from '../../common/ViewButton'
import { Button } from '@mui/material'
import { Alert, Form, Modal } from 'react-bootstrap'
import CommonBtn from '../Common_Fun/CommonBtn'
import useMailSender from '../Reports/Hooks/useMailSender'
import AlertDismissible from '../Common_Fun/AlertDismissible'
import ApiCall from '../Constants/ApiCall'
import ReportTemplate from '../Reports/ReportTemplate'
import HandleGeneratePdf from '../Reports/handlePdf'
import booking from "../../assets/css/bookingen.module.css";


const HouseKeepingStatusreport = (props) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [allCheckboxes, setAllCheckboxes] = useState([]);

  const [warningMsg, setWarningMsg] = useState("");
  const [products, setProducts] = useState([])
  const [msg, setMsg] = useState("");
  const [startDate, setStartDate] = useState('');
  const [houseKeepData, setHouseKeepData] = useState({});

  const [endDate, setEndDate] = useState('');
  const [disable, setDisable] = useState(false);
  const [blobPdf, setBlobPdf] = useState([]);

  const [pdfGen, setPdfGen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoad] = useState(false)
  const [gotPdf, setGotPdf] = useState(false);
  const [msgType, setMsgType] = useState('danger');


  const [houseData, setHouseData] = useState([])

  const user_permissions = JSON.parse(localStorage.getItem("user_permission"));
  const assignpermission =  user_permissions && user_permissions['HouseKeeper-Task']

  const {
    SendMail,
    loading: sendMailLoading,
    error: sendMailError,
    setError: setSendMailError,
    message,
    setMessage,
  } = useMailSender();
  const title = {
    'Assigned By': ['assign_by', 'text'],'Assign To': ['housekeeper', 'text'],  'Room #': ['room', 'number'], 'Room Type': ['room_type', 'text'], 'Housekeeper Status': ['task_status', 'select'],
    Bathroom: ['bathroom','amenity'],
    Towels: ['towels','amenity'],
    "Bed Sheets": ['bed_sheets','amenity'],
    Drapes: ['drapes','amenity'],
    Vacuum: ['vacuum','amenity'],
    Dusting: ['dusting','amenity'],
    Other: ['other','amenity'],
  }

  const buttonType = () => {

  }
  const roomStats = [
    "Available",
    "Under Maintenance",
    "Out Of Order",
    "Occupied",
    " O/Needs-Service",
    "V/Dirty"
  ]
  const trSelect = {
    "Room Status": roomStats,
  };
  const HouseKeepStats = [
    "To-Do",
    "In-Progress",
    "Done"
  ]
  let selectValues = {
"Housekeeper Status": HouseKeepStats,
    "Room Status": roomStats,

  }
  const handleRole = (e, value) => {
    const status = e.target.value; // Get status from event
    setHouseKeepData(prevState => ({
      ...prevState,
      [value.id]: status // Update the specific id with the new status
    }));
  };
  console.log(houseKeepData, 'dkdkdk')
  const formattedData = {
    "method": "update",
    "house_keep": houseKeepData
  };




  const gethousekeeper = async () => {
    setLoad(true)
    let property = localStorage.getItem("property");

    const msg = await ApiCall(`${props.url}house-keeper-task/${property}`, "get")

    if (msg?.data?.length > 0) {
      console.log(msg, 'house')
      setHouseData(msg?.data)
      setGotPdf(true);
      setLoad(false)

    }
    else{
      setLoad(false)
      setHouseData([])
    }


  }
  useEffect(() => {

    gethousekeeper()


  }, [])

  const dropdownStyle = {
    width: "220px",
    height: "40px",
    padding: "8px 12px",
    border: "1px solid gray",
    borderRadius: "8px",
    backgroundColor: "#f5f5f5",
    fontSize: "16px",
    outline: "none",

    cursor: "pointer",

  };
  const handleSendData = (email) => {
    if (blobPdf.length > 0) {
      SendMail(
        props.url,
        email.email,
        startDate,
        endDate,
        "Housekeeper Status",
        {},
        blobPdf
      );
    } else {
      setMsg("No Report found to send!");
    }
  };
  const msgFun = () => {
    setMsg("");
    setMessage("");
    setSendMailError("");
  };
  const applystatus = async () => {
    if (Object.keys(houseKeepData).length === 0) {
      setTimeout(() => {
        setMsg('Please select at least one status')
        setMsgType('danger')
      }, 500)
      return
    }
    else {

      let property = localStorage.getItem("property");

      const msg = await ApiCall(`${props.url}house-keeper-task/${property}`, "post", formattedData)
      if (msg) {
        if (msg?.msg?.includes('We are not allow to change')) {
          setTimeout(()=>{
            setMsg('You are not allowed to change the room status from Occupied')
            setMsgType('danger')
          },500)
        }
        else {
          setMsg(msg.msg)
          setMsgType('success')
          setTimeout(()=>{
            window.location.reload()
          },1000)
        }

      }
    }
  }
  console.log(houseData, 'dkdkdkk')
  return (
    <div style={{ padding: 10 }}>
      {msg !== "" && (
        <AlertDismissible msgFun={msgFun} time={6} type={msgType} msg={msg} />
      )}
      {message && (
        <AlertDismissible
          msgFun={msgFun}
          time={6}
          type={"success"}
          msg={message}
        />
      )}
      {sendMailError && (
        <AlertDismissible
          msgFun={msgFun}
          time={6}
          type={"danger"}
          msg={sendMailError}
        />
      )}
      <div>
        <div style={{ display: "flex", gap: "20px" }}>
          {/* <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Date:</label>
            <input type="date" style={dropdownStyle} />
          </div> */}

          <div style={{ width: 1000 }}>


            <CommonBtn
              data={houseData}
              type="pdf"
              sendMailLoading={sendMailLoading}
              handleSendData={handleSendData}
              disable={disable}
              setPdfGen={setPdfGen}
              loading2={loading}

            />
          </div>
        </div>
      </div>
      <br />
      {loading1 ? <Tables

        print={true}
        data={houseData}
        input={true}
        title={title}

        loading2={loading1}
      /> : <>


        <Tables
          print={true}

          data={houseData}
          input={true}
          title={title}
          trSelect={trSelect}
          select_val={selectValues}
          thSelectFun={handleRole}
          loading2={loading1}


        />
        <ReportTemplate email={true} data={houseData} title={title} input={false} />
        {pdfGen && <HandleGeneratePdf setDisable={setDisable} gotPdf={gotPdf} blobPdf={blobPdf} setGotPdf={setGotPdf} setBlobPdf={setBlobPdf} data={houseData} />}

        <br />
       



      </>}


    </div>
  )
}

export default adminLayout(HouseKeepingStatusreport)