import React, { useEffect, useState } from 'react';
import '../../assets/css/coupons.css';
import Skeletonloader from '../../components/skeletonloader/Skeletonloader';
import { Navigate, useNavigate } from 'react-router-dom';
import ApiCall from '../Constants/ApiCall';
import AlertDismissible from '../Common_Fun/AlertDismissible';
import moment from 'moment';

const HistoryCoupons = ({ data, loading, coupon, url,editC,setEditC,setActiveTab,couponpermission }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [coupons, setCoupons] = useState(coupon);
  const [couponDeleted, setCouponDeleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleDeleteClick = (coupon) => {
    console.log(coupon.id);

    setSelectedCoupon(coupon);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedCoupon(null);
  };
  console.log(couponpermission,'sksksk')
  const handleConfirmDelete = async () => {
    if (!selectedCoupon) return;

    try {
      const property = localStorage.getItem("property");
      const api_call = await ApiCall(
        `${url}generate_coupon_code/${property}/${selectedCoupon.id}/`,
        "delete"
      );
      if (api_call?.msg === "Coupon deleted successfully") {
        console.log("Deleting coupon:", selectedCoupon);
        setCoupons((prevCoupons) =>
          prevCoupons.filter((coupon) => coupon.id !== selectedCoupon.id)
        );
        setCouponDeleted(true);
      }
      else if(api_call?.error){
        setErrorMsg('Coupon is already used. You cannot delete this coupon.')
      }
    } catch (error) {
      console.error("Error while deleting the coupon:", error);
      alert("An error occurred while deleting the coupon. Please try again.");
    } finally {
      handleModalClose(); // Close the modal regardless of the outcome
    }
  };

  const msgFun = () => {
    setCouponDeleted(false);
    setErrorMsg(null);
  };
  const navigate = useNavigate()
  const handleEditClick=(cou)=>{
    setActiveTab('create new')
    setEditC(cou)
  }
const handleback=()=>{
  setActiveTab('')
}
  return (
    <div id="table-container">
      <img onClick={handleback} style={{ cursor: 'pointer' }} title='Go Back' src={require('../../assets/images/Group 83.png')} alt='group' />
      <br/>
      <br/>
      <h2>All Coupons</h2>
      {coupons.length > 0 ? (
        <table id='table'>
          <thead id='thead-couponhead' >
            <tr>
              <th id='th'>Name</th>
              <th id='th'>Category</th>
              <th id='th'>Code</th>
              <th id='th'>From</th>
              <th id='th'>To</th>
              <th id='th'>Created at</th>
              <th id='th'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon, index) => (
              <tr key={index}>
                <td id='td'>{loading ? <Skeletonloader /> : coupon.title}</td>
                <td id='td'>{loading ? <Skeletonloader /> : coupon.category}</td>
                <td id='td'>{loading ? <Skeletonloader /> : coupon.code}</td>
                <td id='td'>
                  {loading ? <Skeletonloader /> : moment(coupon.valid_from).format('MM-DD-YYYY')}
                </td>
                <td id='td'>
                  {loading ? <Skeletonloader /> : moment(coupon.valid_to).format('MM-DD-YYYY')}
                </td>
                <td id='td'>
                  {loading ? <Skeletonloader /> : moment(coupon.create_date).format('MM-DD-YYYY')}
                </td>
                <td id='td'>
                  {loading ? (
                    <Skeletonloader />
                  ) : (
                    <>
                      <button onClick={() => handleEditClick(coupon)} className="edit-btn">Edit</button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteClick(coupon)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            marginTop: "20px",
          }}
        >
          No Coupons Available
        </div>
      )}

      {couponDeleted && (
        <AlertDismissible
          msgFun={msgFun}
          time={5}
          type={"success"}
          msg={"Coupon Deleted Successfully"}
        />
      )}

      {errorMsg && (
        <AlertDismissible
          msgFun={msgFun}
          time={5}
          type={"danger"}
          msg={errorMsg}
        />)}

      {isModalVisible && (
        <div
          className="modal active"
          id="delete_room"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="delete_room"
          aria-hidden="false"
          style={{ display: "block" }}
        >
          <form action="" method="POST" className="my-2">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ borderRadius: "10px" }}>
                <div
                  className="modal-header"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "rgb(71, 173, 255)",
                  }}
                >
                  <h5 className="modal-title" id="delete_facility">
                    Delete Coupon
                  </h5>
                  <span
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleModalClose}
                  >
                    <span style={{ color: "white" }} aria-hidden="true">
                      &times;
                    </span>
                  </span>
                </div>
                <div className="modal-body">
                  Do you want to delete the coupon{" "}
                  <b>{selectedCoupon?.title}</b>?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    style={{
                      background: "rgb(71, 173, 255)",
                      color: "white",
                      width: "100px",
                    }}
                    onClick={handleModalClose}
                    className="btn"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    style={{
                      background: "rgb(71, 173, 255)",
                      color: "white",
                      width: "100px",
                    }}
                    onClick={handleConfirmDelete}
                    className="btn"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default HistoryCoupons;
