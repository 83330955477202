export function countAvailableFacilities(facilitiesData) {
    console.log(facilitiesData ,Object.keys(facilitiesData)?.length,'facilitiesData')
    if(Object.keys(facilitiesData)?.length < 1){
        if (!facilitiesData || typeof facilitiesData !== "object") {
            console.error("Invalid facilities data provided.");
            return 0;
        }
    }
    else{
        let count = 0;

        Object.values(facilitiesData).forEach(category => {
            if (category && Object.keys(category).length > 0) {
                Object.values(category).forEach(value => {
                    if (value === "Yes") {
                        count++;
                    }
                });
            }
        });
    
        return count;
    }
    

   
}

// Example usage:

