import Tables from "../Constants/Tables";
import AlertDismissible from "../Common_Fun/AlertDismissible"
import { useEffect, useState } from 'react';
import TablePrint from "../../common/TablePrint";


const ReportTemplate = (props) => {
	const { setMinute, setSecond } = props

	const [msg, setMsg] = useState(`Page conversion into PDF will take.`)
	const msgFun = () => {
	}



	// 
	let check = false
	
	return (
		<>
			{<>

				{/* {props.data.length >= 50 && <AlertDismissible  msgFun={msgFun}  type={"notice"} msg={msg} asTime={2} />} */}

				<div style={{ position: 'absolute', left: '-11000px' }}>
					<div className="pdf_table" style={{ display: 'none' }} >
						<br />
						{/* <TablePrint/> */}
						<Tables id={true} addTime={props.addTime} limit={300} email={props.email} print={props.print} data={props.data} input={props.input} title={props.title} page={props.page} />
					</div>
				</div></>}
		</>
	);
};

export default ReportTemplate;