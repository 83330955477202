import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import SidebarData from "../Pages/Constants/SidebarData";
import "../assets/css/searchoptimised.css";
import iconp from "../assets/images/akar-icons_file.png";
import { useNavigate } from "react-router-dom";
import InfoDetail from "../Pages/Constants/Information";
import CloseIcon from '@mui/icons-material/Close';
import { weightage } from "./stripekeys";

const predefinedTop5Names = [
    "Room-Reservation",
    "Booking-Engine",
    "Room-Type",
    "Property-Management",
    "Housekeeping"
];


const SearchOptimised = ({ collapsed }) => {
    const inputRef = useRef();

    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [hoveredItem, setHoveredItem] = useState('Room Type');
    const [sidebarItems,setSidebarItems] = useState(SidebarData)
    

    const handleClose = () => {
        setShow(false);
        setSearchQuery("");
        setFilteredResults([]);
    };

    const NormQuery = (query) => {
        const normalizedQuery = query?.replace(/-/g, " "); // Remove dashes
        return normalizedQuery

    }
    
    


   

    // Extract predefined items that exist in SidebarData
    const predefinedMatches = [];
    sidebarItems.forEach((category) => {
        if (predefinedTop5Names.includes(category.heading)) {
            predefinedMatches.push({ name: category.heading, icon: iconp });
        }
        Object.keys(category.subheading).forEach((sub) => {
            if (predefinedTop5Names.includes(sub)) {
                predefinedMatches.push({ name: sub, icon: iconp });
            }
        });
    });
    const navigate = useNavigate()
    const HoverDescription = (item) => {
        setHoveredItem(NormQuery(item?.name))
    }
useEffect(()=>{
    const newItems = [
        {
          id: SidebarData.length + 1,
          heading: "Roles & Permission",
          subheading: {
            "Permission-Management": ["permission"],
            "Manage-Roles": ["roles"]
          }
        },
        {
          id: SidebarData.length + 2,
          heading: "Dashboard",
          subheading: {
            "Dashboard": ["dashboard"],
          }
        }
      ];
      setSidebarItems([...SidebarData, ...newItems]); // Spread both new items
    },[])
     useEffect(() => {
    if (show) {
      inputRef.current.select();
    }
  }, [show]);
    const handleSearch = (query) => {
        setSearchQuery(query);
    
        if (query.trim() === "") {
            setFilteredResults([]);
            setHoveredItem("Room Type");
            return;
        }
    
        // ✅ Step 1: Preprocess Query
        const normalizedQuery = query.toLowerCase().trim().replace(/\s*,\s*/g, ", ");
        const queryWords = normalizedQuery.split(/\s+/); // Split into words
        let results = [];
        const addedPaths = new Set();
    
        // ✅ Step 2: Search Logic
        sidebarItems.forEach((category) => {
            const normalizedHeading = category.heading.toLowerCase().replace(/-/g, " ").trim();
    
            Object.entries(category.subheading).forEach(([sub, paths]) => {
                const fullPath = `${category.heading} > ${sub}`;
                if (addedPaths.has(fullPath)) return;
    
                let weight = 0;
                let exactPhraseCount = 0;
                let multiWordMatchCount = 0;
                let singleWordMatchCount = 0;
                let partialMatchCount = 0;
    
                const normalizedSub = sub.toLowerCase().replace(/-/g, " ").trim();
                const smallPath = NormQuery(sub);
                const normalizedInfoText = InfoDetail[smallPath]?.toLowerCase()?.replace(/\s*,\s*/g, ", ")?.trim() || "";
    
                // 🔍 Exact Phrase Match
                const phraseRegex = new RegExp(`\\b${normalizedQuery}\\b`, "gi");
                exactPhraseCount += (normalizedSub.match(phraseRegex) || []).length;
                exactPhraseCount += (normalizedInfoText.match(phraseRegex) || []).length;
    
                // 🔍 More than 1-word Exact Match
                if (queryWords.length > 1) {
                    let matchWords = queryWords.filter(word => normalizedSub.includes(word) || normalizedInfoText.includes(word));
                    if (matchWords.length === queryWords.length) {
                        multiWordMatchCount += 1;
                    }
                }
    
                // 🔍 Exact Match Per Word (Whole Words Only)
                queryWords.forEach((word) => {
                    const wordRegex = new RegExp(`\\b${word}\\b`, "gi"); // Ensures whole word match
                    singleWordMatchCount += (normalizedSub.match(wordRegex) || []).length;
                    singleWordMatchCount += (normalizedInfoText.match(wordRegex) || []).length;
                });
    
                // 🔍 Partial Matches (Only valid prefixes, not substrings inside words)
                queryWords.forEach((word) => {
                    const partialRegex = new RegExp(`\\b${word}\\w*\\b`, "gi"); // Matches only valid prefixes
                    partialMatchCount += (normalizedSub.match(partialRegex) || []).length;
                    partialMatchCount += (normalizedInfoText.match(partialRegex) || []).length;
                });
    
               
                weight += exactPhraseCount * 100 * queryWords.length; // Exact phrase match
                weight += multiWordMatchCount * 20; // More than one-word exact match
                weight += singleWordMatchCount * 5; // Exact match per word
                weight += partialMatchCount * 1; // Partial word match (only valid prefixes)
    
                
    
                if (weight > 0) {
                    addedPaths.add(fullPath);
                    results.push({
                        name: sub,
                        path: fullPath,
                        mostviwed: predefinedTop5Names?.includes(sub) || false,
                        weight,
                    });
                }
            });
        });
    
        // ✅ Step 3: Sort by Weight (Highest First) and Update State
        results.sort((a, b) => b.weight - a.weight);
        setHoveredItem(NormQuery(results[0]?.name));
        setFilteredResults([...results]);
    };
    
    
    
    
    
    
    const highlightSearchTerm = (text, query) => {
        if (!query || !text) return text; 
    
       
        const queryWords = query.toLowerCase()
            .replace(/\s*,\s*/g, ", ") // Keep "add, edit" format
            .trim()
            .split(/\s+/); // Split by spaces
    
      
        const escapedWords = queryWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    
        
        const regex = new RegExp(`(${escapedWords.join('|')})`, "gi");
    
      
        return text.replace(regex, `<span class="highlight">$1</span>`);
    };
    

    
    return (
        <>
            {collapsed ? (
                <SearchIcon onClick={() => setShow(true)} style={{ color: "white", cursor: "pointer" }} />
            ) : (
                <div onClick={() => setShow(true)} style={{ position: "relative", display: "flex", alignItems: "center" }}>
                    <SearchIcon
                        style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "white",
                            cursor: "pointer",
                        }}
                    />
                    <Form.Control
                        className="sidebarsearchdetails"
                        type="text"
                        placeholder="Search..."
                        style={{ paddingLeft: "35px", height: "36px", width: "100%", background: "rgb(7, 71, 166)", cursor: "pointer" }}
                    />
                </div>
            )}
            <Modal onHide={handleClose} show={show} className="roomModal facroommodal" >

                <Modal.Body>
                    <div onClick={() => setShow(false)} style={{ float: "right", width: "fit-content", cursor: "pointer" }}>
                        <CloseIcon style={{ width: 20, height: 20, marginTop: 15 }} />
                    </div>

                    <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                        <SearchIcon style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "#2383E2" }} />
                        <Form.Control
                            className="sidebarsearchdetails_modal"
                            type="text"
                            ref={inputRef}
                            placeholder="Search anything in PMS..."
                            style={{ paddingLeft: "35px", height: "50px", width: "100%", border: "none" }}
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                    <hr />
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "49%", height: '300px', overflowY: "auto" }}>

                            {/* Predefined Matched Items */}

                            {searchQuery === "" && predefinedMatches.length > 0 && (
                                <>
                                    <div>
                                        <h5 style={{ fontSize: 14, marginLeft: 7, color: "#fa707b" }}>Most Visited Pages</h5>
                                    </div>
                                    <ul className="search-results">
                                        {predefinedMatches.map((item, index) => (
                                            <li style={{ background: hoveredItem === NormQuery(item.name) ? '#EFF8FF' : '' }} onMouseEnter={() => HoverDescription(item)} onClick={() => navigate(`/${item.name === 'Dashboard' ? '' :item.name}`)} key={index} className="search-item">
                                                <img src={item.icon} alt="icon" /> <span className="search-titleoptimsed">{NormQuery(item.name)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {/* Dynamic Search Results */}
                            {searchQuery !== "" && (
                                <>
                                    {filteredResults.length > 0 && (
                                        <div>
                                            <h5 style={{ fontSize: 14, marginLeft: 7, color: "#fa707b" }}>Best Matches</h5>
                                        </div>
                                    )}
                                    <ul className="search-results">
                                        {filteredResults.length > 0 ? (
                                            filteredResults.map((result, index) => (
                                                <li style={{ background: hoveredItem === NormQuery(result.name) ? '#EFF8FF' : '', borderBottom: "1px solid #A9A9A980" }} onClick={() => navigate(`/${result.name === 'Dashboard' ? "" :result.name}`)} onMouseEnter={() => setHoveredItem(NormQuery(result?.name))} key={index} className="search-item">
                                                    <img style={{ filter: "grayscale(100%)" }} src={iconp} alt="icon" /> <span className="search-titleoptimsed">{NormQuery(result.name)}</span> &nbsp; {result?.mostviwed && <span style={{ fontSize: 10, background: "#E3F0FA", padding: 8, borderRadius: 10, color: "#2383E2" }}>{result?.mostviwed && 'Most Viewed'}</span>}
                                                    <span className="search-path">{NormQuery(result.path)} {weightage && <>&nbsp;(<b style={{color:"black",fontSize:10}}>{result.weight}</b>)</>}</span>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="search-item">No results found</li>
                                        )}
                                    </ul>
                                </>
                            )}
                        </div>
                        &nbsp;&nbsp;
                        {searchQuery === "" && <div style={{ borderRight: "1px solid rgb(184, 184, 184)" }}></div>}
                        {searchQuery !== "" && filteredResults?.length > 0 && <div style={{ borderRight: "1px solid rgb(184, 184, 184)" }}></div>}
                        <div style={{ padding: 10, width: "55%", minHeight: "330px" }}>
                            {hoveredItem && InfoDetail[hoveredItem] ? (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                        <img style={{ marginTop: -9 }} src={iconp} alt="icon" />
                                        <h5 style={{ marginBottom: "10px" }}>{hoveredItem}</h5>
                                    </div>
                                    <div style={{ padding: "10px", background: "#EFF8FF", borderRadius: "5px" }}>
                                        <p style={{ color: "#5E5E5E" }}
                                            dangerouslySetInnerHTML={{
                                                __html: highlightSearchTerm(InfoDetail[hoveredItem], searchQuery)
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                filteredResults?.length > 0 && <div style={{ padding: "10px", color: "#888" }}>No Information available.</div>
                            )}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SearchOptimised;