import React from "react";
import { Link } from "react-router-dom";
import HeaderConvertDateTime from "../Pages/Common_Fun/HeaderConvertDateTime";
import head from "../assets/css/header.module.css";
import ApiCall from "../Pages/Constants/ApiCall";
import loacalAvatar from "./../assets/images/user-profile.png";
import Video from "./../Pages/Constants/Video";
import question from "./../assets/images/question.png";
import infoicon from "./../assets/images/Vector (4).png";
import systemdateinfo from "./../assets/images/Calendar.svg";
import InfoDetail from "../Pages/Constants/Information";
import ConvertLatter from "../Pages/Common_Fun/ConvertLatter";
import { ShepherdTour, TourMethods } from "react-shepherd";
import steps from "../Pages/Constants/steps";
import Tour from "../Pages/Dashboard/Tour";
import TourDetail from "../Pages/Constants/TourDetails";
import "shepherd.js/dist/css/shepherd.css";
import Skeletonloader from "../components/skeletonloader/Skeletonloader";
import { Tooltip, Typography } from "@mui/material";

const tourOptions = {
  defaultStepOptions: { showCancelLink: true },
  useModalOverlay: true,
  keyboardNavigation: true,
};
class Header extends React.Component {
  constructor(props) {
    super(props);
    const currentTimeParts = this.props.time ? this.props.time.split(":") : ["00", "00", "00"];
    this.state = {
      url: this.props.url,
      auditDate:this.props.auditDate,
      date: this.props.date,
      currentTime: "",
      hours: parseInt(currentTimeParts[0]),
      minutes: parseInt(currentTimeParts[1]),
      second: parseInt(currentTimeParts[2]),
      isDropdownOpen: false,
      name: this.props.roleName,
      profilePic: "",
      isProfilePicLoaded: false,
      responseData: {
        name: this.props.roleName,
        role: this.props.role,
      },
    };
  }



  updateTime = () => {
    this.state.responseData.name = this.props.roleName;
    this.state.responseData.role = this.props.role;
    let new_hours = this.state.hours;
    let new_minutes = this.state.minutes;
    let new_second = this.state.second + 1;
    let new_date = this.state.date;
    this.setState({ second: new_second });

    if (new_second > 59) {
      this.setState({ minutes: new_minutes + 1 });
      this.setState({ second: 0 });
      new_second = 0;
    }

    if (new_minutes > 59) {
      this.setState({ hours: new_hours + 1 });
      this.setState({ minutes: 0 });
      new_minutes = 0;
    }

    if (new_hours >= 24) {
      this.setState({ hours: 0 });
    }

    let show_hour = new_hours;
    let show_minute = new_minutes;
    let show_second = new_second;
    if (show_hour <= 9) {
      show_hour = `0${show_hour}`;
    }
    if (show_minute <= 9) {
      show_minute = `0${show_minute}`;
    }
    if (show_second <= 9) {
      show_second = `0${show_second}`;
    }
    const currentTime = `${show_hour}:${show_minute}:${show_second}`;
    this.setState({ currentTime });
    const dateString = String(new_date);
    const dateParts = dateString.split("-");
    const day = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]) - 1;
    const year = parseInt(dateParts[0]);
    const date = new Date(year, month, day);
    if (new_hours >= 24) {
      date.setDate(date.getDate() + 1);
    }
    this.setState({ date: HeaderConvertDateTime(date, "year") });
  };

  async componentDidMount() {
    if (!this.state.isProfilePicLoaded) {
      await this.getProfilePicture();
      this.setState({ isProfilePicLoaded: true });
    }
    this.updateTime();
    this.interval = setInterval(() => this.updateTime(), 1000);
  }
  componentDidUpdate(prevProps) {
    if (this.props.time !== prevProps.time) {
      const currentTimeParts = this.props.time ? this.props.time.split(":") : ["00", "00", "00"];
      this.setState({
        hours: parseInt(currentTimeParts[0]),
        minutes: parseInt(currentTimeParts[1]),
        second: parseInt(currentTimeParts[2]),
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

convertToAmPm(timeString) {
    // Split the time string into hours and minutes
    let [hours, minutes, second] = timeString.split(':');
    
    // Convert hours to a number
    hours = parseInt(hours, 10);
  
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    hours = hours % 12 || 12;
    
    if( String(hours).length <=1){
      hours = `0${hours}`
    }
  
    // Return formatted time
    sessionStorage.setItem('auditSysTime',`${hours}:${minutes}:${second} ${ampm}`)
    return `${hours}:${minutes}:${second} ${ampm}`;
  }

  getProfilePicture = async () => {
    const storedProfilePic = sessionStorage.getItem("profilePictureUrl");
    if (storedProfilePic) {
      this.setState({ profilePic: storedProfilePic });
    } else {
      try {
        const data_user = JSON.parse(localStorage.getItem("data"));
        const id = data_user.userid;
        const body = { user_id: id };

        const property = localStorage.getItem("property");
        const result = await ApiCall(
          `${this.state.url}get_profile_image/${property}`,
          "post",
          body
        );
        sessionStorage.setItem("profilePictureUrl", result.data[0]);

        this.setState({ profilePic: result.data[0] });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  render() {
    const leftValue = JSON.parse(localStorage.getItem("collapsed"));
    const { currentTime, date, isDropdownOpen, responseData, profilePic , auditDate } =
      this.state;
    return (
      <>
        <nav
          style={{ left: leftValue && leftValue ? "70px" : "240px" }}
          className="navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom"
        >
          <div className="container-fluid">
            <div id="highlightquestionmark" className="d-flex flex-column">
              <div style={{display:"flex",gap:3}}>
              <h1 className={head.Title} id="headerTitle" >
              {this.props.pageLoaded ? <>  {this.props.title}</> :<Skeletonloader width={'140px'} height={'35px'} variant={'text'}/>}
               
              </h1>
              {this.props.sub in Video && (
                
                  <a target="_blank" href={`${Video[this.props.sub]}`}>
                   {this.props.pageLoaded ? <Tooltip title="Click this for video" arrow > <img
                      className="highlightnewiconquestion"
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: 8,
                        borderRadius: 999,
                      }}
                      
                      src={question}
                      alt="Question"
                    /></Tooltip>:<Skeletonloader width={'35px'} height={35} variant={'circular'}/>}
                  </a>
                )}
              &nbsp;
                {this.props.title in TourDetail && (
                  <>
                  {this.props.pageLoaded ?<Tooltip
                    title="Watch Onboarding Tour"
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#000",
                        color: "white", 
                        fontSize: "12px",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#000", 
                      },
                    }}
                  >
                    <div> 
                      <ShepherdTour steps={steps} tourOptions={tourOptions}>
                        <TourMethods>
                          {(tourContext) => (
                            <Tour
                              dashboard={this.props.title}
                              url={this.state.url}
                              context={tourContext}
                            />
                          )}
                        </TourMethods>
                      </ShepherdTour>
                    </div>
                  </Tooltip>:<Skeletonloader width={'35px'} height={35} variant={'square'}/>}
                  </>
                )}
              </div>
              <div style={{display:"flex",gap:2}}>
              <h1 className={head.subTitle} >
               {this.props.pageLoaded ? <> {this.props.name}{" "}</> : <Skeletonloader width={'100px'} height={'35px'} variant={'text'}/>}
                
              </h1>
              {this.props.title in InfoDetail && (
                  <>{this.props.pageLoaded ? 
                    <Tooltip title={`${InfoDetail[this.props.title]}`} placement="right" arrow  
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#000", 
                        color: "white", 
                        fontSize: "12px",
                        maxWidth: "70vw",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#000", 
                      },
                    }}>
                  <img
                    style={{
                      paddingLeft: "10px",
                      height: "25px",
                      width: "35px",
                    }}
                    src={infoicon}
                    alt="Info"
                  /> </Tooltip>:<Skeletonloader width={'35px'} height={35} variant={'circular'}/>}</>
                )}

{this.props.title in InfoDetail && (
                  <>{this.props.pageLoaded ? 
                    <Tooltip 
  title={
    <React.Fragment>
      <Typography style={{fontSize:"12px"}} component="span" fontWeight="bold">
        The displayed date format is subject to change based on your system date format.
      </Typography>
      
      Your operating system determines the date style based on its locale configuration. If the format appears different from what you expect, it's because your system is set to use this specific format. You can change it in your system's date and time settings if needed.
    </React.Fragment>
  } 
  placement="right" 
  arrow  
  sx={{
    "& .MuiTooltip-tooltip": {
      backgroundColor: "#000", 
      color: "white", 
      fontSize: "12px",
      maxWidth: "70vw",
    },
    "& .MuiTooltip-arrow": {
      color: "#000", 
    },
  }}
>
  <img
    style={{
      paddingLeft: "10px",
      height: "30px",
      width: "35px",
    }}
    src={systemdateinfo}
    alt="Info"
  />
</Tooltip>
:<Skeletonloader width={'35px'} height={35} variant={'circular'}/>}</>
                )}
              </div>
            </div>

            <div className="dropdown">
              <a
                href="/"
                className=""
                id="dropdownUser2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
               
                <div
                  className="ms-auto d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleDropdown}
                  onBlur={this.closeDropdown}
                  tabIndex="0"
                >
                  <Link id="profilepichighlight" to="/profile">
                    <div className="d-flex gap-3 align-items-center">
                      {this.props.timeloaded ? <img
                        src={
                          profilePic === "undefined" ? loacalAvatar : profilePic
                        }
                        alt={"Profile"}
                        width="32"
                        height="32"
                        className="rounded-circle me-2"
                      /> :<Skeletonloader width={'35px'} height={35} variant={'circular'}/>}
                      <div className="d-flex flex-column">
                        <span className={head.dropdownNav}>
                          {this.props.pageLoaded ? <>  {ConvertLatter(responseData.name, "first")} {""}</> :<Skeletonloader width={'130px'} height={'25px'} variant={'text'}/>}
                        </span>
                        <span
                        className={head.dropdownNav2}
                          style={{
                            color: "grey",
                          }}
                        >
                          {this.props.pageLoaded ? <>{ConvertLatter(responseData.role, "first")}</> :<Skeletonloader width={'130px'} height={'15px'} variant={'text'}/>}
                          {this.props.pageLoaded ? <>
                            <i
                            style={{ paddingLeft: "5px" }}
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                          {""}{" "}
                          </> :""}
                        </span>
                        <span className={head.dt} >
                         {(this.props.pageLoaded && this.props.timezonename !='' && currentTime) ? <> {HeaderConvertDateTime(this.props.date, "date")} {this.convertToAmPm(currentTime)}</> :<Skeletonloader width={'130px'} height={'15px'} variant={'text'}/>}
                        </span>
                        <span className={head.dt} >
                          <i title="Last Successful Audit Date">Last Audit </i> :  {this.props.auditDate ? ( <><i>{HeaderConvertDateTime(this.props.auditDate, "date")}</i> </>) : (<Skeletonloader width={'130px'} height={'15px'} variant={'text'} /> )}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </a>
              <ul
                className="dropdown-menu text-small shadow"
                aria-labelledby="dropdownUser2"
                style={{ marginTop: "10px", marginLeft: "20px" }}
              >
                <li>
                  <Link className="dropdown-item" to="/profile">
                    <i
                      className="fa fa-user-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Profile
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      localStorage.removeItem("logintoken");
                      localStorage.removeItem("property");
                      localStorage.removeItem("collapsed");
                      sessionStorage.removeItem("profilePictureUrl");
                      sessionStorage.removeItem("persistentFormData");
                      sessionStorage.removeItem("name");
                      sessionStorage.removeItem("role");
                      sessionStorage.removeItem("propertyData");
                      sessionStorage.removeItem("SalesData");
                      sessionStorage.removeItem("editPolicy");
                      sessionStorage.removeItem("AddPolicy");
                      sessionStorage.removeItem("propertyDataAdd");
                      sessionStorage.removeItem("propertyDataEdit");
                      sessionStorage.removeItem("GuestData");
                      sessionStorage.removeItem("CheckInData");
                      sessionStorage.removeItem("NotesData");
                      localStorage.removeItem("roleName");
                      localStorage.removeItem("address");
                      sessionStorage.removeItem("redirect");
                      sessionStorage.removeItem("currentTime");
                      window.location.href = "/login";
                    }}
                  >
                    <i
                      className="fa fa-sign-out"
                      aria-hidden="true"
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    ></i>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
