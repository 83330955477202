import React from "react";
import Form from "react-bootstrap/Form";
import "../../assets/css/coupons.css";

export const FilterPanel = ({ filters, setFilters }) => {


  const Categories = ["Amenities", "Items", "RoomType"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetFilters = () => {
    setFilters({
      title: "",
      category: "",
      startDate: "",
      endDate: "",
    });
  };

  return (
    <div className="filter-panel my-3">
      <h3>Filter</h3>
      <hr className="dotted-line" />

      {/* <label>Title</label> */}
      <input
        type="text"
        name="title"
        placeholder="Title"
        value={filters.title}
        onChange={handleInputChange}
        style={{
          marginBottom: "15px",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          width: "100%",
        }}
      />

      {/* Category Filter */}
      <div className="custom-select" style={{ marginBottom: "15px" }}>
        {/* <label>Category</label> */}
        <select
          className="custom-selectcoupontype"
          name="category"
          value={filters.category}
          onChange={handleInputChange}
          style={{
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            width: "100%",
            background:"#edf6ff"
          }}
        >
          <option value="">Select Category</option>
          {Categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      {/* Date Filter */}
      <label>Start Date</label>
      <input
        aria-label="Start Date"
        type="date"
        name="startDate"
        value={filters.startDate}
        onChange={handleInputChange}
        style={{
          marginBottom: "15px",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          width: "100%",
        }}
      />
      <label>End Date</label>
      <input
        aria-label="End Date"
        type="date"
        name="endDate"
        value={filters.endDate}
        onChange={handleInputChange}
        style={{
          marginBottom: "15px",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          width: "100%",
        }}
      />

      <hr className="dotted-line" />

     
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={handleResetFilters}
          style={{
            backgroundColor: "white",
            borderColor: "rgba(71, 173, 255, 1)",
            border: "1px solid rgba(71, 173, 255, 1)",
            color: "rgba(71, 173, 255, 1)",
            borderRadius: "15px",
            padding: "5px 15px",
            cursor: "pointer",
          }}
        >
          Reset Filter
        </button>
      </div>
    </div>
  );
};
