import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from '../Pages/Common_Fun/CloseButton'
import "../assets/css/commonfacility.css"
import defaultImage from "../assets/images/ph_star.png";
import { facilityImages } from '../Pages/Constants/facilityimage';
import "../assets/css/form_x.css"

const FacilityModelComponent = (props) => {
    const handleClose = () => {
        props.setIsFacModel(false)
    }


    return (
        <Modal
            show={props.isFacmodel}
            className="roomModal  facroommodal"
            onHide={handleClose}
            backdrop="static"


        >
            <Modal.Header closeButton>
                <Modal.Title>Room Facilities</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                overflow: "scroll",
                height: 500,
            }}>

                <div className="facility_container">
                    {Object.keys(props.facData?.roomType?.facilitys)?.length > 0 ? <>
                        {Object.entries(props.facData?.roomType?.facilitys)
                            .filter(([_, facilities]) =>
                                Object.values(facilities).some(value => value === "Yes") // Remove empty categories
                            )
                            .map(([category, facilities]) => (
                                <div key={category} className="facility-section">
                                    <h4 className="facility-heading">
                                        {category
                                            .replace(/_/g, " ") // Replace underscores with spaces
                                            .replace(/\b\w/g, (char) => char.toUpperCase())} {/* Capitalize first letter of each word */}
                                    </h4>
                                    <hr /> {/* Add an <hr> right after the category title */}
                                    <div className="grid">
                                        {Object.entries(facilities)
                                            .filter(([_, value]) => value === "Yes") // Show only facilities with "Yes"
                                            .map(([facility, _]) => (
                                                <div key={facility} className="facility-card">
                                                    <img
                                                        src={facilityImages[facility] || defaultImage}
                                                        alt={facility}
                                                        className="facility-image"
                                                    />
                                                    <p className="facility-name">{facility}</p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </> : "No Facilities Available"}

                </div>

            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <CloseButton onClick={() => handleClose()} />
            </Modal.Footer>
        </Modal>
    )
}

export default FacilityModelComponent