import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone';

function ConvertDateTime(get_date, data, type = null) {

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  function checkDateFormat(dateString, formatString) {
    var regex = new RegExp(formatString);
    return regex.test(dateString);
  }

  let dateTime;
  if(type === "timezone"){
    // Handle timezone using moment-timezone
    const timestampWithoutOffset = get_date.substring(0, get_date.lastIndexOf('-'));
    const timezoneOffset = get_date.substring(get_date.lastIndexOf('-'));

    dateTime = moment(timestampWithoutOffset);
    const timezoneOffsetHours = parseInt(timezoneOffset.substring(1, 3));
    const timezoneOffsetMinutes = parseInt(timezoneOffset.substring(4));
    const totalOffsetMinutes = (timezoneOffsetHours * 60 + timezoneOffsetMinutes) * (timezoneOffset[0] === '-' ? 1 : -1);

    dateTime = dateTime.utcOffset(totalOffsetMinutes);
    console.log("Converted time with timezone offset: ", dateTime.format('YYYY-MM-DD HH:mm:ss'));
  }

  // Convert time
  if (data === "time") {
    let time = get_date;
    
    if (get_date.includes(".")) {
      time = get_date.split(".")[0];
    }

    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  } else if (data === "dateT") {
    // Convert time to localized time string
    const date = moment(get_date);
    return date.format('hh:mm A');  // Format time as 12-hour with AM/PM
  } else {

    let format = /^\d{4}-\d{2}-\d{2}$/;
    let parts;
    let date;

    if (type === "format") {

      if (checkDateFormat(get_date, format)) {
        date = moment(get_date);
      } else {
        if (get_date.includes('/')) {
          parts = get_date.split('/');
        }
        else {
          parts = get_date.split('-');
        }
        date = moment([parts[2], parts[1] - 1, parts[0]]);
      }

    } else {
      date = moment(get_date);
    }

    let day = date.date();
    const theDay = day;
    let month = date.month() + 1;
    let year = date.year();

    if (day <= 9) {
      day = '0' + day;
    }
    if (month <= 9) {
      month = '0' + month;
    }
    
    let convert;
    if (data === "day") {
      convert = theDay;
    }
    if (data === "Y") {
      convert = year;
    }
    if (data === "date") {
      convert = `${day}-${month}-${year}`;
    }
    if (data === "year") {
      convert = `${year}-${month}-${day}`;
    }
    if (data === "month") {
      convert = `${month}-${day}-${year}`;
    }

    if (data === "week") {
      let dayIndex = date.day();
      convert = dayNames[dayIndex];
    }

    if (data === "monthView") {
      let monthIndex = date.month();
      convert = monthNames[monthIndex];
    }

    return convert;
  }

}

export default ConvertDateTime;
