const SubSidebar = {
  Amenities: ["Amenities-Bookings", "Amenities-Notes"],
  Rooms: [
    "Room-Assignments",
    "Room-Change-Report",
    "Room-Status-Report",
    "Coupon-History-Report",
    "Key-Reports",
    "Room-Notes",
  ],
  "Daily Reports": [
    "Daily-Revenue",
    "Daily-Report",
    "Daily-Collection-Report",
    "Daily-Collection-Payment",
    "Daily-Deposit-Report",
    "Ledger-Summary",
    "Daily-Summary",
    "Daily-Shift-Report",
  ],
  "Financial Reports": [
    "Financial-Reports",
    "Payment-Report",
    "Production-Reports",
    "Account-Balances",
    "Sell-Inventory-Report",
  ],
  "Safety & Security": ["Police"],
};

export default SubSidebar;
