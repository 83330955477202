import moment from 'moment-timezone';


const getCurrentTimeZone = () => {
    const timeZone = moment.tz.guess();
    if (!timeZone) {
        throw new Error('Unable to determine current timezone.');
    }
    return timeZone;
};


const getTimeAfterFetchingTimeZone = (timeZone, includeDate = false, includeTime = false, format) => {
    const userTimeZone = getCurrentTimeZone(); // Mandatory call
    const newTimeZone = localStorage.getItem('timezone') || timeZone;
    const now = moment().tz(newTimeZone);

    // Use custom format if provided
    if (format) {
        return now.format(format);
    }

    // Use flags to determine the format if no custom format is provided
    if (includeDate && includeTime) {
        return now.format('YYYY-MM-DD HH:mm:ss');
    } else if (includeDate) {
        return now.format('YYYY-MM-DD');
    } else if (includeTime) {
        return now.format('HH:mm:ss');
    } else {
        return new Date().toString(); // Default fallback
    }
};

const getTimeWithAddedDays = (timeZone, format = 'YYYY-MM-DD', addDays = 0) => {
    const newTimeZone = localStorage.getItem('timezone') || timeZone;
    const currentTime = moment().tz(newTimeZone);

    // Add the specified number of days to the date
    const updatedTime = currentTime.add(addDays, 'days');

    // Return the formatted date
    return updatedTime.format(format);
};
const getTimeWithSubDays = (timeZone, format = 'YYYY-MM-DD', subDays = 0) => {
    const newTimeZone = localStorage.getItem('timezone') || timeZone;
    const currentTime = moment().tz(newTimeZone);

    // Add the specified number of days to the date
    const updatedTime = currentTime.add(subDays, 'days');

    // Return the formatted date
    return updatedTime.format(format);
};

const convertDateToTimeZone = (date, timeZone, format = 'YYYY-MM-DD') => {
    const newTimeZone = localStorage.getItem('timezone') || timeZone;
    return moment.tz(date, newTimeZone).format(format);
};

function convertToTimezone(date, timeZone) {
    const newTimeZone = localStorage.getItem('timezone') || timeZone;
    // If date is not provided, use the current date for the given timezone
    if (date === null) {
        // Try fetching date from localStorage, or fallback to the current date
        const storedDate = localStorage.getItem('date');
        if (storedDate) {
            // If there's a date in localStorage, parse it
            return moment.tz(storedDate, newTimeZone);
        } else {
            // If no date is found in localStorage, get the current date in the timezone
            return moment.tz(newTimeZone);
        }
    } else {
        // If a date is provided, convert it to the given timezone
        return moment.tz(date, newTimeZone);
    }
}

export { getCurrentTimeZone, getTimeAfterFetchingTimeZone ,getTimeWithAddedDays,convertDateToTimeZone,getTimeWithSubDays,convertToTimezone};
