import { useEffect, useState } from 'react';

export default function useMailSender() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const ApiCall = async (url, token, formData, count,pdfs,key) => {
    try {
      setLoading(true);

      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      });
    

      console.log("->>>>");

      for (var pair of formData.entries()) {
        // Do something with formData entries
      }

      setError('');
    } catch (error) {
      console.log(error)
      setMessage('The email has been successfully sent to your email address. It may take 5-10 minutes to arrive due to the large file size.');
    } finally {
      setMessage('Email sent successfully with reports');
      setLoading(false);
    }
  };

  const SendMail = async (url, email, startDate = null, endDate = null, type, charts, pdfs) => {
    const property = localStorage.getItem('property');
    const token = JSON.parse(localStorage.getItem('logintoken'));
    const api = `${url}email_report_api/${property}`;
    const formData = new FormData();

    formData.append('chart', JSON.stringify(charts));
    formData.append('type', type);

    if (startDate !== null) {
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
    }
    formData.append('email', email);
    let check = 0
    let size = 0
    let key  = `pdf0`
    let current_size = 0
    for (let count in pdfs[0]) {
      let check_size = pdfs[0][count].size / (1024 * 1024)
      let next_size = pdfs[0][parseInt(count)+1] 
      let next
      current_size +=check_size;
      key = `pdf${check}`
      console.log(check);
      if(next_size !==undefined){
        console.log("ikkk")
        next = current_size + next_size.size /(1024 * 1024) 
        if(next>20){
          formData.append(key, pdfs[0][count]);
          await ApiCall(api, token['token'], formData, count,pdfs,key);
          check = 0
          current_size = 0
        }else{
          console.log("--else-")
          formData.append(key, pdfs[0][count]);
          check +=1

        }
      }else{
        console.log(key)
        formData.append(key, pdfs[0][count]);
        await ApiCall(api, token['token'], formData, count,pdfs,key);
        check = 0
        current_size = 0
      }
   
    }
  };

  return {
    SendMail,
    loading,
    error,
    setError,
    message,
    setMessage,
  };
}
