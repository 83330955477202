import React, { useState, useEffect } from 'react';
import adminLayout from '../../../hoc/adminLayout';
import Form from 'react-bootstrap/Form';
import { Alert, Button } from 'react-bootstrap';
import PostReport from '../../../Pages/Common_Fun/PostReport';
import Tables from '../../../Pages/Constants/Tables';
import Loading from '../../../images/Loading';
import AlertDismissible from '../../../Pages/Common_Fun/AlertDismissible';
import CheckError from '../../../Pages/Common_Fun/CheckError';
import { useNavigate } from 'react-router-dom';
import ReusableHookForm from '../../Constants/ReusableHookForm';
import { customBtnStyle, formFields2 } from '../ReportComponents/Utility';
import ReportTemplate from '../ReportTemplate';
import HandleGeneratePdf from '../handlePdf';
import useMailSender from '../Hooks/useMailSender';
import ApiCall from '../../Constants/ApiCall';
import CommonBtn from '../../Common_Fun/CommonBtn';
import Skeletonloader from '../../../components/skeletonloader/Skeletonloader';
import { convertDateToTimeZone, getTimeAfterFetchingTimeZone, getTimeWithAddedDays } from '../../Dashboard/utils/timeUtils';

const RoomCleaning = (props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  const [endDate, setEndDate] = useState('');
  const [showData, setShowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('danger');
  const [blobPdf, setBlobPdf] = useState([]);
  const [gotPdf, setGotPdf] = useState(false);
  const [selectList, setSelectList] = useState([])
  const [pdfGen, setPdfGen] = useState(false)
  const [disable, setDisable] = useState(false)
  const [warningMsg, setWarningMsg] = useState("");
  const [sError,setSError] = useState(false)
  const [endError,setEndError] = useState(false)

  const { SendMail, loading: sendMailLoading, error: sendMailError, setError: setSendMailError, message, setMessage } = useMailSender();

  const handleSendData = (email) => {
    if (blobPdf.length > 0) {
      SendMail(props.url, email.email, startDate, endDate, 'account_balances', {}, blobPdf);
    } else {
      setMsg('No Report found to send!')
    }

  };

  const title = {
    "Assigned by": ['value["assign_by"]["user"]["first_name"]', 'text'],
    "Room-Number": ['value["rooms"]["number"]', 'number'],
    "Housekeeper Name": ['value["housekeeper"]["name"]','text'],
    'Room Type': ['value["rooms"]["roomType"]["room_all_type"]', 'text'],
    "Room Status": ['value["rooms"]["roomStatus"]', 'select'],
    "Assigned Date": ['assign_date', 'date', 'date'],
    "Assigned Time": ['assign_time', 'onlytime', 'text'],
    "Updated By": ['value["updated_by"]["user"]["first_name"]', 'text'],
    "Housekeeper Status": ['task_status', 'select'],
    "Complete Date":["complete_date","date"],
  };

  const addTime = { complete_date: 'complete_time' };

  useEffect(() => {
    const timeZone = getTimeAfterFetchingTimeZone(props.timeZonename, true, false, 'YYYY-MM-DD')
    setLoading(true)
    let datetime = timeZone
    let newDateAdd = getTimeWithAddedDays(props.timeZonename, 'YYYY-MM-DD', 1)
    const data = {
      start_date: datetime,
      end_date: newDateAdd,
    };
    updateRates(data).then(() => {
      setLoading(false)
    });

  }, []);




  const updateRates = async (tim) => {
    const startD = convertDateToTimeZone(tim.startDate, props.timeZonename, 'YYYY-MM-DD');
    const endD = convertDateToTimeZone(tim.endDate, props.timeZonename, 'YYYY-MM-DD');
    if(tim.start_date ==="" && tim.end_date ===""){
      setSError('Start Date is required')
      setEndError('End Date is required')
      return
    }
    else if(tim.start_date === ""){
      setSError('Start Date is required')
      return
     
    }
    else if(tim.end_date ===""){
      setEndError('End Date is required')
      return
    }
    if (startDate > endDate) {
      setSError(false)
      setEndError(false)
      setTimeout(() => {
        setMsg("Start date can not be greater than End date");
        setMsgType('danger')
       
      }, 500);
      return;
    }
    setStartDate(tim.start_date);
    setEndDate(tim.end_date);
    setSearchLoading(true);
    let property = localStorage.getItem('property');
    let url = `${props.url}house-keep-report/${property}`;

    let data = await ApiCall(url, 'post', {
      start_date: tim.start_date,
      end_date: tim.end_date
    });
    if (data) {
      console.log(data.data, 'dkdkdkd')
      setShowData(data.data);
      let final = data.data


      let status = ['Available', 'Under Maintenance', 'Out Of Order', 'Occupied', ' O/Needs-Service', 'V/Dirty']
      let housekStatus = ['To-Do', 'In-Progress', 'Done']
      let selectValues = {
        "Housekeeper Status":housekStatus,
        "Room Status": status,
      }
      setSelectList(selectValues)
      if (Array.isArray(final)) {
        if (final.length <= 0) {
          setWarningMsg('No data availaible')
          // let ms = "Data does not exist"
          // setMsg(ms)
        }
      }
      setSearchLoading(false);
      setGotPdf(true);
    }





  };

  const msgFun = () => {
    setMsg('');
    setMessage('');
    setSendMailError('');
  };

  return (
    <div>
      {msg && <AlertDismissible msgFun={msgFun} time={6} type={msgType} msg={msg} />}
      {message && <AlertDismissible msgFun={msgFun} time={6} type={'success'} msg={message} />}
      {sendMailError && <AlertDismissible msgFun={msgFun} time={6} type={'danger'} msg={sendMailError} />}
      <div className='row'>
        <div className="row d-flex align-items-end">
          <div style={{height:80}} className="col-md-4 col-lg-3">
            <Form.Group id="dob">
              {loading ? <Skeletonloader width={'20%'} height={30} variant={'text'} /> : <>
              
              <Form.Label>Start Date</Form.Label>
             
              </>
              
              }
              {loading ? <Skeletonloader width={'100%'} height={60} variant={'text'} /> : <>
              
              <Form.Control
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                value={startDate}
                onKeyDown={(e) => e.preventDefault()}
                type="date"
                id="check_in"
                name="dob"
              />
               {sError !== false && <small style={{color:"red"}}>{sError}</small>}
              </>
              
              }
            </Form.Group>
          </div>
          <div style={{height:80}} className="col-md-4 col-lg-3">
            <Form.Group id="dob">
              {loading ? <Skeletonloader width={'20%'} height={30} variant={'text'} /> : <Form.Label>End Date</Form.Label>}
              {loading ? <Skeletonloader width={'100%'} height={60} variant={'text'} /> : <>
              
              <Form.Control
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                value={endDate}
                onKeyDown={(e) => e.preventDefault()}
                type="date"
                id="check_out"
              />
                {endError !== false && <small style={{color:"red"}}>{endError}</small>}
              </>}
            </Form.Group>
          </div>
          <div style={{height:50}}  className="col-md-4">
            {loading ? <Skeletonloader width={'30%'} height={60} variant={'text'} /> : <Button
              onClick={() => updateRates({ start_date: startDate, end_date: endDate })}
              id="paymentreports"
              variant="primary"

            >
              Search
            </Button>}
          </div>
        </div>
        <div className='my-3'>
          {/* <ReusableHookForm
              formFields={formFields2}
              onSubmit={handleSendData}
              customBtnStyle={{ ...customBtnStyle }}
              buttonText={sendMailLoading ? 'Sending...' : 'Send'}
            /> */}
          <CommonBtn data={showData} type="pdf" sendMailLoading={sendMailLoading}
            handleSendData={handleSendData} disable={disable} setPdfGen={setPdfGen} loading2={loading} />

        </div>

        <div>
          {loading || searchLoading ? (
            <Tables
              addL={{ "Rent": "$" }}
              print={true}
              data={showData}

              input={true}
              title={title}
              page={'ArrivalsReport'}
              loading2={loading || searchLoading}
            />
          ) :
            <>
              <Tables
                addL={{ "Rent": "$" }}
                print={true}
                data={showData}
                select_val={selectList}
                input={true}
                title={title}
                addTime={addTime}
                page={'ArrivalsReport'}
                loading={loading}
              />
              <ReportTemplate email={true} data={showData} title={title} input={false} />
              {pdfGen && <HandleGeneratePdf setDisable={setDisable} gotPdf={gotPdf} blobPdf={blobPdf} setGotPdf={setGotPdf} setBlobPdf={setBlobPdf} data={showData} />}
            </>
         
          }
        </div>
      </div>
    </div>
  );
};

export default adminLayout(RoomCleaning)