import React, { useEffect, useRef, useState } from 'react';
import "../../assets/css/coupons.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import booking from "../../assets/css/bookingen.module.css";
import { convertDateToTimeZone } from '../Dashboard/utils/timeUtils';
import moment from 'moment';
import ApiCall from '../Constants/ApiCall';
import AlertDismissible from '../Common_Fun/AlertDismissible';
import { Dropdown } from 'react-bootstrap';
import Select from 'react-select'

const CouponForm = (props) => {
    console.log(props, 'props')
    const sessionDate = sessionStorage.getItem("date");
    const newDate = convertDateToTimeZone(sessionDate, props.timeZonename, 'MM-DD-YYYY')
    const endDate = moment(newDate).add(1, 'days').format('MM-DD-YYYY')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [imageError,setImageError] = useState(false)
    const [handle, setHandle] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState({
        category: props.editC ? props.editC.category : "RoomType",
        roomType: props.editC
            ? props.editC.roomType.map((room) => room.id) // Map `roomType` to an array of IDs
            : [],
        max_days: props.editC ? props.editC?.max_days === null ? '' :props.editC.max_days : '',
        min_days: props.editC ? props.editC?.min_days === null ?'' : props.editC.min_days :'',
        couponType: props.editC 
            ? props.editC.discount > 0 
                ? "discount" 
                : props.editC.fixedAmount > 0 
                    ? "fixedAmount" 
                    : "fixedAmountPer" 
            : "discount",
        dateRange: { start: props.editC ? moment(props.editC?.valid_from).format('MM-DD-YYYY') : newDate, end: props.editC ? moment(props.editC?.valid_to).format('MM-DD-YYYY') : endDate },
        image: props.editC ? props.editC?.img : null,
        title: props.editC ? props.editC.title : '',
        discount: props.editC ? props.editC?.discount : '',
        fixedAmount: props.editC ? props.editC?.fixedAmount : '',
        fixedAmountPer: props.editC ? props.editC?.fixedAmountPer : '',
        max_amount:props.editC ? props.editC?.max_amount :'',
        min_amount:props.editC ? props.editC?.min_amount :'',
        days: props.editC ? props.editC?.days : [],
        bookingEng: props.editC ? props.editC?.bookingEng : false,
        reusable: props.editC ? props.editC?.reusable : false,



    });
    const days = [ "Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const getDaysInRange = (start, end) => {
        // let current = new Date(start);
        // let selectedDayNames = [];
        let selectedDayNames = new Set();
        
        let current = new Date(start);
        let endDay = new Date(end);
    
        while (current <= endDay) {
            let localDate = new Date(current.getFullYear(), current.getMonth(), current.getDate()); // Ensure local time
            let dayIndex = localDate.getDay();  // Get correct weekday index
            selectedDayNames.add(days[dayIndex]);  // Add day name to Set
            
            current.setDate(current.getDate() + 1);  // Move to the next day
        }
    
        return Array.from(selectedDayNames); // Convert Set to Array
    };
    
    const [errors, setErrors] = useState({});
    const [selectedBannerImage, setSelectedBannerImage] = useState([]);
    const [bannerPreviews, setBannerPreviews] = useState([]);
    const [selectedDays, setSelectedDays] = useState(props.editC? props.editC.days: getDaysInRange(newDate,endDate));

const [disabledDays, setDisabledDays] = useState([]);

    const [bookingEng, setBookingEng] = useState(props.editC? props.editC.bookingEng:false);

console.log(props,'ppppp')


    useEffect(() => {
       
            const bucketDays = getDaysInRange(formData?.dateRange?.start, formData?.dateRange?.end);
            const newDays = days.filter(day =>
                !bucketDays.includes(day)
            );
            setDisabledDays(newDays)
            console.log(newDays, 'newdays')
       
       

    }, [selectedDays, formData?.dateRange?.start, formData?.dateRange?.end ])


    useEffect(() => {
        if (props.editC && props.editC?.img != "" && props.editC?.img !=='img') {
            setBannerPreviews([{
                previewUrl: props.editC?.img
            }])
        }
    }, [])
    console.log(selectedDays, 'dkdkdk')

    const fileInputRef = useRef(null);
    console.log(formData,'formData')
    const validateForm = () => {
        const newErrors = {};
        if(!trimS(formData.title)) newErrors.title = "Title is required.";
        if (!formData.category) newErrors.category = "Category is required.";
        // if (formData.category === "roomtype" && !formData.roomType) {
        //     newErrors.roomType = "Room type is required.";
        // }

        if (formData.category !== "Items") {
            // if (!formData.max_days || isNaN(formData.max_days) || formData.max_days <= 0) {
            //     newErrors.max_days = "Enter a valid Max Days.";
            // }
            // if (!formData.min_days || isNaN(formData.min_days) || formData.min_days <= 0) {
            //     newErrors.min_days = "Enter a valid Min Days.";
            // }
            if ((formData.min_days !== '' || formData.max_days !=="" )) {
                if (Number(formData.min_days) >= Number(formData.max_days)) {
                    newErrors.min_days = "Minimum days should be less than maximum days.";
                    newErrors.max_days = "Maximum days should be greater than minimum days.";
                }
            }
        }

        if (!formData.couponType) newErrors.couponType = "Coupon type is required.";
        if (!formData.dateRange.start || !formData.dateRange.end) {
            newErrors.dateRange = "Date range is required.";
        }
        if(selectedDays <= 0) newErrors.days = "Select days";

        if (!formData.title) newErrors.title = "Title is required.";
        if(formData.title.length > 20) newErrors.title = "Title should not exceed 20 characters.";
        if (formData.roomType?.length <= 0 && formData?.category === 'RoomType') {
            newErrors.roomType = "Rooms is required.";
        }

        // CouponType-specific validation
        if (formData.couponType === "discount" && (!formData.discount || formData.discount <= 0)) {
            newErrors.discount = "Enter a valid percentage discount.";
        }
        if (formData.couponType === "fixedAmount" && (!formData.fixedAmount || formData.fixedAmount <= 0)) {
            newErrors.fixedAmount = "Enter a valid fixed amount.";
        }
        if (formData.couponType === "fixedAmountPer" && (!formData.fixedAmountPer || formData.fixedAmountPer <= 0)) {
            newErrors.fixedAmountPer = "Enter a valid fixed rate.";
        }
      
        if (formData.category === "Items") {
            if (!formData.min_amount || formData.min_amount <= 0) {
                newErrors.min_amount = "Enter a valid minimum amount.";
            } 
            if (!formData.max_amount || formData.max_amount <= 0) {
                newErrors.max_amount = "Enter a valid maximum amount.";
            }
            if (Number(formData.min_amount) > 0 && Number(formData.max_amount) > 0) {
                if (Number(formData.min_amount) >= Number(formData.max_amount)) {
                    newErrors.min_amount = "Minimum amount should be less than maximum amount.";
                    newErrors.max_amount = "Maximum amount should be greater than minimum amount.";
                }
            }
        }

        return newErrors;
    };
    

    
    const handleSelectDay = (day) => {
        if (disabledDays.includes(day)) return; 
        setSelectedDays((prev) => {
            const updatedDays = prev.includes(day) 
                ? prev.filter((d) => d !== day)  // Remove if already selected
                : [...prev, day];  // Add if not selected
    
            // Update formData with the new selected days
            setFormData((prevFormData) => ({
                ...prevFormData,
                days: updatedDays
            }));
    
            return updatedDays; // Ensure state updates correctly
        });
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value,e.target, 'name, value');
        
        if (name === "title") {
            if (!trimS(value)) { // Checks if there is at least one non-space character
                setErrors((prev) => ({ ...prev, [name]: "Title is required." }));
            } else {
                setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error if valid
            }
        }
        setFormData((prev) => {
            // Check if the category is not "RoomType" and clear roomType if true
            if (name === "category" && value !== "RoomType") {
                return {
                    ...prev,
                    [name]: value,
                    roomType: [], // Clear roomType if category is not 'RoomType'
                };
            }
            if (name === "bookingEng") {
                return {
                    ...prev,
                    [name]: !prev[name], // Toggle true/false dynamically
                };
            }
            if (name === "reusable") {
                return {
                    ...prev,
                    [name]: !prev[name], // Toggle true/false dynamically
                };
            }
    

            // Update discount/fixedAmount dynamically based on couponType
            if (name === "couponType") {
                return {
                    ...prev,
                    couponType: value,
                    discount: value === "discount" ? "" : undefined, // Clear discount if couponType is 'discount'
                    fixedAmount: value === "fixedAmount" ? "" : undefined, // Clear fixedAmount if couponType is 'fixedAmount'
                    fixedAmountPer: value === "fixedAmountPer" ? "" : undefined, // Clear fixedAmount if couponType is 'fixedAmount'
                };
            }

            

            // Default behavior for other form fields
            return {
                ...prev,
                [name]: value,
            };
        });

        // setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error on change
    };

    console.log(errors, formData, 'errors')
    const handleEvent = (event, picker) => {
        // setFormData((prev) => ({
        //     ...prev,
        //     dateRange: {
        //         start: picker.startDate.format("MM-DD-YYYY"),
        //         end: picker.endDate.format("MM-DD-YYYY"),
        //     },
        // }));
        const startDate = new Date(picker.startDate.format("YYYY-MM-DD"));
        const endDate = new Date(picker.endDate.format("YYYY-MM-DD"));
    
        // Get all days that fall within the selected range
        const daysInRange = getDaysInRange(startDate, endDate);
        console.log(startDate,endDate,daysInRange, 'daysInRange');
        
        
        setSelectedDays(daysInRange); // Auto-select days in range
        console.log(selectedDays, 'selectedDays');
        
        // setDisabledDays(days.filter(day => !daysInRange.includes(day))); 

        setFormData((prev) => ({
            ...prev,
            dateRange: {
                start: picker.startDate.format("MM-DD-YYYY"),
                end: picker.endDate.format("MM-DD-YYYY"),
            },
            days: daysInRange, // ✅ Ensure selected days are stored in formData
        }))
        setErrors((prev) => ({ ...prev, dateRange: "" }));
    };
    // Function to get unique days within the selected range
  

   const  trimS = (str) => {
        str = str.trim().replace(/\s+/g, " ");
        return str;
      };

    const handleBannerImageChange = (e) => {
        const files = Array.from(e.target.files);
        const validImageFiles = files.filter(file => file.type.startsWith("image/"));
    
        if (validImageFiles.length !== files.length) {
            setImageError("Please upload only image files.");
        }
    
        if (validImageFiles.length > 0) {
            setSelectedBannerImage(validImageFiles);
            const previews = validImageFiles.map(file => ({
                file,
                previewUrl: URL.createObjectURL(file),
            }));
            setBannerPreviews(previews);
            setHandle(false);
        }
    };
    

    const removeImage = () => {
        setFormData((prev) => ({ ...prev, image: null }));
        setBannerPreviews([])
        setSelectedBannerImage([])
        setHandle(true)
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            const formDataToSend = new FormData();
            formDataToSend.append('method', 'save');
            formDataToSend.append('valid_to', moment(formData?.dateRange?.end).format('YYYY-MM-DD'));
            formDataToSend.append('valid_from', moment(formData?.dateRange?.start).format('YYYY-MM-DD'));
            formDataToSend.append('days', JSON.stringify(selectedDays));
            formDataToSend.append('bookingEng', formData?.bookingEng);
            formDataToSend.append('reusable', formData?.reusable);
            formDataToSend.append('title', formData?.title);
            formDataToSend.append('category', formData.category);

            // Append image if it's available
            if (selectedBannerImage?.length > 0) {
                formDataToSend.append('img', selectedBannerImage[0]);
            }
            else {
                if (handle) {
                    formDataToSend.append('rm_img', true)
                }

            }

            // Add discount or fixedAmount based on formData
            if (formData.discount) {
                formDataToSend.append('discount', formData.discount);
            } else if(formData.fixedAmount) {
                formDataToSend.append('fixedAmount', formData.fixedAmount ? formData.fixedAmount : 0);
            }
            else{
                formDataToSend.append('fixedAmountPer', formData.fixedAmountPer ? formData.fixedAmountPer : 0);
            }

            // Add roomType based on category
            if (formData.category !== 'RoomType') {
                // formDataToSend.append('roomType', []);  // If roomType is empty
            } else {
                formDataToSend.append('roomType', JSON.stringify(formData.roomType)); // Append array of room types
            }

            // Add max_days and min_days for certain categories
            if (formData.category !== 'Items') {
                formDataToSend.append('max_days', formData?.max_days || '');
                formDataToSend.append('min_days', formData?.min_days || '');
            } else {
                formDataToSend.append('max_days', '');
                formDataToSend.append('min_days', '');
                formDataToSend.append('max_amount', formData?.max_amount);
                formDataToSend.append('min_amount', formData?.min_amount);
            }

            // Now, update formData with the body values
            setFormData((prevState) => {
                const updatedFormData = Object.fromEntries(formDataToSend.entries());
            
                // List of checkboxes that should always be boolean
                const booleanFields = ["reusable", "bookingEng"];
            
                booleanFields.forEach((field) => {
                    if (field in updatedFormData) {
                        updatedFormData[field] = updatedFormData[field] === "true" || updatedFormData[field] === true; 
                    }
                });
            
                return {
                    ...prevState,
                    ...updatedFormData,
                };
            });

            // Send the FormData object via POST request


            handlePostCoupon(formDataToSend);


            console.log(formDataToSend, 'formDataToSend');
            
           
        }
    };

    
    const handlePostCoupon = async (data) => {
        const property = localStorage.getItem("property");
        let api
        if (props.editC) {
            api = `${props.url}generate_coupon_code/${property}/${props.editC.id}/`
        }
        else {
            api = `${props.url}generate_coupon_code/${property}/`
        }


        const token = JSON.parse(localStorage.getItem("logintoken"));
        const apiResponse = await fetch(api, {
            method: props.editC ? 'PUT' : "POST",
            headers: {
                Authorization: "Bearer " + token["token"],
            },
            body: data,
        });
        const responseJson = await apiResponse.json(); // Assuming the response is in JSON format
        console.log(responseJson, 'responseJson');
        
    if (apiResponse.ok) {
        if (responseJson.error && responseJson.error.includes("Name already exists")) {
            setError("Coupon name already exists. Please choose a different name.");
            setTimeout(() => {
                props.setActiveTab('');
            }, 2000);
            
        }
        else{

            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
                props.setActiveTab('');
            }, 5000);
        }
    } 
    }
    const handleCancel = () => {
        if(props.editC){
            props.setActiveTab('history');
        }
        else{
            props.setActiveTab('');
        }
        console.log("Form Canceled");
       
    };
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    }
    const handleCheckboxChange = (e, categoryId) => {
        const { checked } = e.target;

        setFormData((prevState) => {
            // Ensure roomType is an array
            const currentRoomTypes = Array.isArray(prevState.roomType)
                ? prevState.roomType
                : [];

            let updatedRoomTypes;
            if (checked) {
                // Add the room type ID if it's not already in the array
                updatedRoomTypes = [...currentRoomTypes, categoryId];
            } else {
                console.log(currentRoomTypes, 'categoryId')
                // Remove the room type ID if it's unchecked
                updatedRoomTypes = currentRoomTypes.filter((existingId) => existingId !== categoryId);
            }

            // Update errors based on selection
            if (updatedRoomTypes.length > 0 && formData?.category === "RoomType") {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    roomType: [], // Clear error message if room types are selected
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    roomType: "Please select rooms", // Show error if no rooms are selected
                }));
            }

            return {
                ...prevState,
                roomType: updatedRoomTypes, // Update roomType with updated list
            };
        });
    };
    const dropdownRef = useRef(null);

    return (
       <>
        <img onClick={handleCancel} style={{ cursor: 'pointer'  }} title='Go Back' src={require('../../assets/images/Group 83.png')} alt='group' />
        <div className="couponforms">
              
                <br/>
                <br/>
            <h4>Create Coupon</h4>
            <div style={{ borderTop: '2px dotted #838997', marginTop: '15px' }}></div>
            {success && (
                <AlertDismissible
                    type="success"
                    msg={`Coupon ${props.editC ? 'edited' : 'saved'} successfully`}
                    msgFun={() => setSuccess(false)}
                />
            )}

            {error && (
                <AlertDismissible
                    type="danger"
                    msg={error}
                    msgFun={() => setError(null)} // Reset error message after dismiss
                />
            )}
            
             {imageError !==false && (
                <AlertDismissible
                    type="danger"
                    msg={`${imageError}`}
                    msgFun={() => setImageError(false)}
                />
            )}
            <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
                {/* Coupon Category */}
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="category" className='couponlabel  required'>Add Title</label>
                    <input
                        id="title"
                        name="title"
                        placeholder='Add Title'
                        className='couponselect'
                        type='text'
                        defaultValue={formData.title}
                        onChange={handleChange}
                       
                    />
                    {errors.title && <p className="error-text">{errors.title}</p>}
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="category" className='couponlabel required'>Select Category</label>
                    <select
                        id="category"
                        name="category"
                        className='couponselect'
                        defaultValue={formData.category}
                        onChange={handleChange}
                    >
                        <option value="RoomType">RoomType</option>
                        <option value="Amenities">Amenities</option>
                        <option value="Items">Items</option>
                    </select>
                    {errors.category && <p className="error-text">{errors.category}</p>}
                </div>

                {/* Select Rooms */}

                {formData.category === "RoomType" && (
                    <div>
                        <label htmlFor="roomType" className='couponlabel required'>Select Rooms</label>
                         <Select
                                isMulti
                                options={props?.cRoomType?.map((category) => ({
                                    value: category.id,
                                    label: category.room_all_type,
                                }))}
                                value={props?.cRoomType?.filter(category => formData.roomType.includes(category.id))
                                    .map(room => ({ value: room.id, label: room.room_all_type }))
                                }
                                onChange={(selectedOptions) => {
                                    const selectedIds = selectedOptions.map((option) => option.value);
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        roomType: selectedIds,
                                    }));
                                    if (selectedIds.length > 0) {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            roomType: "",
                                        }));
                                    } else {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            roomType: "Please select rooms", 
                                        }));
                                    }
                                }}
                          
                                placeholder="Select Rooms"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: "17px", 
                                    }),
                                    multiValue: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: "17px", // Apply border radius to selected options
                                        backgroundColor: "#e0e0e0", // Optional: Change background color for better visibility
                                        padding: "3px 5px",
                                    }),
                                    multiValueLabel: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "#333", // Change text color if needed
                                    }),
                                    multiValueRemove: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: "50%", // Make the remove (X) button rounded
                                        ":hover": {
                                            backgroundColor: "black",
                                            color: "white",
                                        },
                                    }),
                                }}
                                // className={`react-select-container ${formData?.roomType?.length > 0 ? 'couponselect1' : 'couponselect'}`}
                                classNamePrefix="react-select"
                            />
                        {errors.roomType && <p className="error-text">{errors.roomType}</p>}
                        </div>
                )}

                {/* Max Days and Min Days */}
                {formData.category !== "Items" && (
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="min_days" className='couponlabel'>Min Days</label>
                            <input
                                id="min_days"
                                name="min_days"
                                placeholder='Min Days'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.min_days : formData.min_days}
                                onChange={(e) => {
                                    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                    if (value.length > 2) value = value.slice(0, 2); // Limit to 2 digits
                                    e.target.value = value; // Set the modified value
                                    handleChange(e); // Call the original change handler
                                  }}
                                  inputMode="numeric"
                                onKeyDown={(e) => {
                                    if (["e", "E", "+", "-"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            {errors.min_days && <p className="error-text">{errors.min_days}</p>}
                        </div>
                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="max_days" className='couponlabel'>Max Days</label>
                            <input
                                id="max_days"
                                name="max_days"
                                placeholder='Max Days'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.max_days : formData.max_days}
                                onChange={(e) => {
                                    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                    if (value.length > 4) value = value.slice(0, 4); // Limit to 2 digits
                                    e.target.value = value; // Set the modified value
                                    handleChange(e); // Call the original change handler
                                  }}
                                  inputMode="numeric"
                                onKeyDown={(e) => {
                                    if (["e", "E", "+", "-"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            {errors.max_days && <p className="error-text">{errors.max_days}</p>}
                        </div>
                    </div>
                )}


{formData.category === "Items" && (
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="min_days" className='couponlabel'>Min Amount</label>
                            <input
                                id="min_amount"
                                name="min_amount"
                                placeholder='Min Amount'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.min_amount : formData.min_amount}
                                onChange={handleChange}
                            />
                            {errors.min_amount && <p className="error-text">{errors.min_amount}</p>}
                        </div>
                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="max_amount" className='couponlabel'>Max Amount</label>
                            <input
                                id="max_amount"
                                name="max_amount"
                                placeholder='Max Amount'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.max_amount : formData.max_amount}
                                onChange={handleChange}
                            />
                            {errors.max_amount && <p className="error-text">{errors.max_amount}</p>}
                        </div>
                    </div>
                )}
                

                {/* Coupon Type */}
                <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                    <div style={{ marginBottom: '15px', width: "100%" }}>
                        <label htmlFor="couponType" className='couponlabel required'>Coupon Type</label>
                        <select
                            disabled={props.editC}
                            id="couponType"
                            style={{ background: props.editC ? '#D3D3D3' : 'white' }}
                            name="couponType"
                            className={`${'couponselect'} ${props.editC ? 'edit-modcoupon' : ''}`}

                            defaultValue={formData.couponType}
                            onChange={handleChange}
                        >
                            <option value="discount">Discount %</option>
                            <option value="fixedAmount">Fixed Discount</option>
                            <option value="fixedAmountPer">Fixed Daily Rate</option>
                        </select>
                        {errors.couponType && <p className="error-text">{errors.couponType}</p>}
                    </div>
                    <div style={{ marginBottom: '15px', width: "100%" }}>

                        {["discount", "fixedAmount","fixedAmountPer"].includes(formData.couponType) && (
                            <>
                                <label
                                    htmlFor="discount"
                                    className='couponlabel required'
                                >
                                    {formData.couponType === 'fixedAmount' ? 'Fixed Amount' : formData.couponType === 'discount' ? 'Discount %':'Fixed Daily Rate'}
                                </label>
                                <input
                                    name={formData.couponType}
                                    className='couponselect' // Dynamically sets the name to "discount" or "fixedAmount"
                                    type="number"
                                    placeholder={{
                                        discount: "Enter Discount %",
                                        fixedAmount: "Enter Fixed Discount",
                                        fixedAmountPer: "Enter Fixed Daily Rate",
                                    }[formData.couponType]}
                                    defaultValue={
                                        props.editC 
                                            ? props.editC.discount > 0 
                                                ? props.editC.discount 
                                                : props.editC.fixedAmount > 0 
                                                    ? props.editC.fixedAmount 
                                                    : props.editC.fixedAmountPer
                                            : formData[formData.couponType] || ""
                                    }
                                    
                                    // defaultValue={props.editC ? props.editC?.discount > 0 ? props.editC?.discount : props.editC?.fixedAmount : formData[formData.couponType] || ""}
                                    onChange={(e) => {
                                        let value = e.target.value.replace(/[^0-9.]/g, ""); // Allow numbers & one decimal
                                        
                                        if ((value.match(/\./g) || []).length > 1) value = value.slice(0, -1); // Prevent multiple decimals
                                    
                                        let [integer, decimal] = value.split(".");
                                    
                                        if (integer.length > 4) integer = integer.slice(0, 4); // Max 2 digits before decimal
                                        if (decimal) decimal = decimal.slice(0, 2); // Max 2 digits after decimal
                                    
                                        value = decimal !== undefined ? `${integer}.${decimal}` : integer; // Preserve decimal if exists
                                    
                                        e.target.value = value;
                                        handleChange(e);
                                      }}
                                    onKeyDown={(e) => {
                                        if (["e", "E", "+", "-"].includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    inputMode="decimal" 
                                    
                                />
                            </>
                        )}
                        {formData.couponType === "discount" && errors.discount && (
                            <p className="error-text">{errors.discount}</p>
                        )}

                        {formData.couponType === "fixedAmount" && errors.fixedAmount && (
                            <p className="error-text">{errors.fixedAmount}</p>
                        )}
                        {formData.couponType === "fixedAmountPer" && errors.fixedAmountPer && (
                            <p className="error-text">{errors.fixedAmountPer}</p>
                        )}
                    </div>
                </div>

                {/* Coupon Date Range */}
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="dateRanges" className='couponlabel required'>Coupon Period</label>
                    <DateRangePicker
                        className={booking.dateRange}
                        onApply={handleEvent}
                        initialSettings={{
                            autoApply: false,
                            showDropdowns: true,
                            locale: { format: "MM-DD-YYYY" },
                            minDate: new Date(),
                        }}
                        showTodayButton={true}
                    >
                        <input
                            type="text"
                            style={{ cursor: "pointer" }}
                            className="couponselect"
                            placeholder="Select Date Range"
                            defaultValue={
                                formData.dateRange.start && formData.dateRange.end
                                    ? `${formData.dateRange.start} - ${formData.dateRange.end}`
                                    : ""
                            }
                            readOnly
                        />
                    </DateRangePicker>
                    {errors.dateRange && <p className="error-text">{errors.dateRange}</p>}
                </div>

                {/* Image Upload */}
                <div style={{ marginBottom: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderRadius: '17px', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <button
                                type="button"
                                style={{
                                    backgroundColor: '#FF943D',
                                    border: '1px solid #ccc',
                                    borderRadius: '12px',
                                    padding: '6px 16px',
                                    cursor: 'pointer',
                                    color: "white",
                                    fontSize: '14px',
                                }}
                                onClick={handleFileInputClick}
                            >
                                Choose File
                            </button>
                            <span style={{ fontSize: '14px', color: 'gray' }}>
                                Choose Coupon Image
                            </span>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleBannerImageChange}
                        />
                        {bannerPreviews?.length > 0 && (
                            bannerPreviews?.map((prev) => (
                                <>
                                    <div style={{ position: 'relative', marginTop: '15px' }}>
                                        <img
                                            src={prev.previewUrl}
                                            alt="Preview"
                                            style={{ width: '100%', height: '270px', objectFit: 'cover', borderRadius: '8px' }}
                                        />
                                        <div
                                            onClick={removeImage}
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: '30px',
                                                height: '30px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            ×
                                        </div>
                                    </div>
                                </>
                            ))
                        )}

                    </div>
                </div>
                <div style={{ marginBottom: 15 }}>
                    <label htmlFor="dateRanges" className='couponlabel required'>Select Days</label>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '17px', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "10px", flexWrap: "wrap" }}>
                        {days.map((day) => (
                            <div
                            key={day}
                            name="days"
                            // onChange={handleChange}
                            onClick={() => handleSelectDay(day)}
                            style={{
                                flex: 1,
                                padding: "10px",
                                borderRadius: "15px",
                                border: "1px solid #007bff",
                                backgroundColor: selectedDays.includes(day) ? "#007bff" : "white",
                                color: selectedDays.includes(day) ? "white" : "#007bff",
                                cursor: disabledDays.includes(day) ? "not-allowed" : "pointer",
                                opacity: disabledDays.includes(day) ? 0.5 : 1,
                                transition: "0.3s",
                            }}
                            >
                            {day}
                            </div>
                        ))}
                        </div>

                    </div>
                    {errors.days && <p className="error-text">{errors.days}</p>}
                      
                </div>

               {/* Checkbox Section */}
                <div style={{ marginBottom: 15 }}>
                    <label className='couponlabel'></label>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px', borderRadius: '17px', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
                        <label style={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                            <input
                                type="checkbox"
                                name="reusable"
                                checked={formData?.reusable} // Ensures controlled state
                                onChange={handleChange}
                                // onChange={() => setBookingEng(!bookingEng)}
                            />
                           Select if this coupon can be used multiple times.
                        </label>
                    </div>
                    
                </div>
               {/* Checkbox Section */}
                <div style={{ marginBottom: 15 }}>
                    <label className='couponlabel'></label>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px', borderRadius: '17px', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
                        <label style={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                            <input
                                type="checkbox"
                                name="bookingEng"
                                checked={formData?.bookingEng} // Ensures controlled state
                                onChange={handleChange}
                                // onChange={() => setBookingEng(!bookingEng)}
                            />
                           Select if this coupon only applies to Booking Engine.
                        </label>
                    </div>
                    {errors.bookingEng && <p className="error-text">{errors.bookingEng}</p>}
                </div>

                {/* Submit and Cancel Buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 350 }}>
                    <button
                        disabled={loading}
                        id='submit'
                        type="submit"
                        className="couponselects"
                        style={{
                            backgroundColor: "#47ADFF",
                            color: "#fff",
                            border: "1px solid #47ADFF",
                            cursor: "pointer",
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                    >
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                    <button
                        type="button"
                        className="couponselects"
                        onClick={handleCancel}
                        style={{
                            backgroundColor: "white",
                            color: "#47ADFF",
                            border: "1px solid #47ADFF",
                            cursor: "pointer"
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
       </>
    );
};

export default CouponForm;