export const InfoDetail = {
  'Payment Report': 'The Payment Report provides an in-depth overview of payment transactions, including Graph Analytics to visually analyze payment trends and patterns. It also includes guest details such as Name, Reservation Date and Time etc.',
  'Account Balances': 'The Account Balances Report allows you to track guest expenses and ensure accurate billing and payment management. It provides detailed information such as Guest Name, Reservation Date and Time etc. This report helps you keep a clear record of guest transactions, ensuring that all payments are correctly accounted for and that any outstanding balances are easily identified for follow-up or resolution.',
  Arrivals: 'Arrivals Reports refers to guests who checked in on specific date',
  'Production Reports':
    'The Production Reports section offers key performance metrics such as RevPar, Occupancy rates, ADR, Room Type Sales Summary, and Country-wise Check-in Guests. You can view these reports with filters for different intervals—monthly, weekly, or daily—to track and analyze performance trends effectively.',
  Departures: 'Departure Reports refers to guests who checked out on specific date',
  'In House': 'This Reports lists all guests who are currently checked in,including additional guests',
  'No Show': 'This report refers to reservations that were marked as no show due to guest non-arrival',
  Cancellations: 'Cancellation reports refers to records that detail reservations or bookings that have been cancelled by guests',
  'Room Assignments': 'This Report suggests that which guests are assigned to specific Rooms',
  Notes: 'Amenities Notes offers flexibility for viewing Notes entered for reservations or guests.',
  'User Reconciliation': 'User Reconcillation Reports refers to paymentactivity for specific user',
  'Daily Revenue': 'The Daily Revenue Report provides a detailed overview of your property daily earnings. It includes multiple breakdowns such as Revenue from different Items, Taxes applied, and any Adjustments made. This report helps you track daily financial performance and manage your property’s income more effectively, ensuring transparency and accuracy in your revenue tracking',
  'Room Change Report':
    'This report provides a comprehensive overview of the room relocations that guests have undergone. It presents detailed information about the changes in their accommodations.',
  
    Police: 'The Police Report displays verified guest details to ensure compliance with security and government procedures. It includes important information such as the "Do Not Rent" (DNR) status of bookings, along with Guest Details and Room details. Additionally, you can create and add Notes by clicking the "Notes" button, allowing you to provide further context or updates related to the booking.',
  'Sell Report': "This report tracks purchases made by the guests or other's from hotel's inventory.",
  'Financial Reports':
    'The Financial Reports section provides a range of reports to track your property financial performance. You can view multiple reports such as Invoices, Commission, Daily Statistics, Adjustments, Taxes, and Ledger reports. These reports help you monitor revenue, expenses, and financial transactions.',
   'Daily Report': 'The Daily Report provides a comprehensive overview, including details such as Booking ID, Room, Guest Name, Check-In, Check-Out, Status, Deposit, Extra Charges, Tax, Advance Payment, and Total. This report also includes information on Discount, Security, Extra Tax, and Payment, helping you track all financial aspects of your bookings and revenue efficiently',
   'Daily Shift Report': 'The Daily Shift Report provides detailed information about the rooms sold during specific shifts by individual employees. It includes key details such as Desk User (employee), Date, Rooms Sold, and Shift Time. This report helps track each employee’s performance and provides insights into room sales for each shift, making it easier to manage operations and ensure accurate record-keeping of sales activity across different time periods.', 
   'Daily Collection Report': 'The Daily Collection Report provides detailed information, including Booking ID, Room, Guest Name, Date, Check-In, Check-Out, Desk User, Advance, Pay Type, Payment, and Total. You can also view a summary or total amount for a particular Pay Type.', 
   'Daily Collection Payment': 'The Daily Collect Payment Report offers a detailed breakdown of your property daily financial transactions. It includes essential information such as Booking ID, Room, Guest Name, Date, Check-In, Check-Out, Desk User, Advance, Pay Type, Payment, and Total. You can also view a summary or total amount for a particular Pay Type.', 
  'Ledger Summary' : 'The Ledger Summary Report provides a comprehensive overview of various financial ledgers within your property. It includes detailed reports on the Cash Ledger Summary, Accounts Ledger Summary, Guest Ledger Summary, Advance Booking Summary, and Deposit Ledger Summary.',
  'Daily Summary' : 'The Daily Summary Report provides an overview of your property financial and operational performance for the day. It includes detailed reports on Rent & Occupancy Tax, Other Charges, Payment, Authorized Payments, Deposits, Business Source Analysis, and Room Status & Statistics. This report helps you monitor daily revenue, track guest payments and deposits, analyze business sources, and evaluate room status and occupancy.', 
  'Employee': 'In this page, manage employees effortlessly. Add, view, and edit employee details seamlessly for efficient team management.',
  'Subscription View':
    'In this page, manage subscriptions easily. Add, view, and modify subscribers accordingly. Check existing subscriptions hassle-free.',
  'Property Details': "Here explore images and location information for a comprehensive view of the property's features and surroundings.",
  'Guest Policy': 'Welcome to the Guest Policy page, where guidelines and regulations ensure a pleasant experience for all visitors.',

  'Permission Management':
    'The Permission Management View allows administrators to assign role-based access, customize permissions, manage user privileges, enforce secure access control and ensure a structured and compliant authorization system.',
  'Role Assign':
    'Manage Roles Page allows you to oversee and organize user roles within the system. Create, modify, or delete roles as needed. Ensure efficient role allocation for effective user management.',

  'Buy Inventory':'In Buy Inventory you can easily purchase multiple items at once to add to your inventory. Simply browse through available items, select the ones you need, and add them to your stock in bulk.',
  'Sell Inventory':'In Sell Inventory you can list and sell items from your inventory seamlessly. You have the flexibility to sell items to different parties, including your Own property, Guests, the Hotel, or Strangers. This page allows you to manage listings, set prices, track sales, and ensure your inventory is efficiently distributed.',

  'Room Reservation': 'The Reservation Management  offers a seamless booking experience, including Check-in & Check-out Date Selection to pick arrival and departure dates ensuring room availability. Room Type Filters to choose from Deluxe, Suite, or any other categories tailored for accommodations. Coupon Selection to apply promo codes or discounts for cost-saving. Room Selection to pick preferred rooms based on special requests. Payment Processing with secure options like credit/debit cards and digital wallets.',
  'Room Move': 'Room Move feature enables you to manage room transfers. Review details of room shifts, including guest movement from one room to another, to ensure a smooth transition during their stay.',
  'Calendar View': 'The Calendar View provides a comprehensive booking and room management experience, allowing users to Create Booking by selecting dates, room types, and guest details. Check All Bookings to view a complete list of reservations with status updates.  Check Room Availability to see how many rooms are available in real-time .',
  'Amenities Reservation': 'The Amenities Reservation provides a seamless booking experience, allowing users to Select Amenities like spa, gym, conference rooms, or pool services; choose between Daily or Hourly Booking based on their needs and proceed with Secure Payment using credit/debit cards, digital wallets, or other methods.',
  'Payment Reconciliation': 'Payment Reconciliation allows you to track and review all payment-related details. Access comprehensive records of transactions, ensuring accuracy and transparency in financial dealings.',
  'Add Amenities':'User can add new amenities with help of this page, please provide the necessary information for each amenity, including the name, rent per day, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!',
  'Edit Amenities':"Users can edit existing amenities using this page. Please provide the necessary information for each amenity that you would like to change, including the name, rent per day, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Dashboard":"Explore real-time arrivals, departures, reservations, and revenue on our dynamic dashboard for seamless management. Manage bookings, check-in/out, and view guest details conveniently on the Reservation page. Today's Activity: View bookings, room nights, and revenue. Filter by date and access comprehensive details at a click.",
  "Amenities Status":"The Amenities Status section allows you to update the status of booked amenities, providing real-time information about their availability. You can also view the history of previously booked amenities, giving you a clear record of past usage. Additionally, you can add notes by clicking the Note button, allowing you to provide additional context or updates regarding specific amenities",
  "Hotel Amenities":"The Hotel Amenities section allows you to view all the amenities currently added to your property. You can create new amenities by clicking the Add Amenities button. To manage the status of any amenity, you can easily edit it by clicking the Edit Status button. Additionally, you can edit or delete amenities by clicking the respective tabs.",
  "Room Type":"The Room Type section enables you to manage the various types of rooms available in the system. You can add, edit, or delete room types here, with each room type containing details such as room category, capacity, and specific amenities. Additionally, you can add rooms to a particular room type by clicking the Add Room button, allowing for a more comprehensive and organized listing of available rooms.",
  "Rates":"In the Rates section, you can set and modify rates for different Accommodation Category, including configuring rack rates, short-term rates, and yearly rates. You can add, edit, or delete rates by clicking the Add Rate, Edit Rate, and Delete Rate buttons. Additionally, the Copy Rate button allows you to copy rates from one period to another, making it easier to manage and apply rates across different time frames or durations.",
  "Room Details":"The Room Details section provides comprehensive information about individual rooms, allowing you to view and manage specific room details such as room number, status, associated room type, and any additional notes or descriptions. You can add remarks by clicking the Add Remark button, change room status, and delete multiple rooms as needed. Additionally, you have the option to delete individual rooms, ensuring efficient and flexible room management.",
  "Room Availability":"The Room Availability page displays a summary of all rooms within the system, with different views such as All Rooms, Available Rooms, and Booked Rooms. These filters allow you to quickly check the current availability status of rooms, view their details, and manage their availability for bookings or reservations. You can easily switch between these views to assess occupancy status, availability, and other relevant information.",
  "Room View":"The Room View Page provides an overview and interactive display of all available rooms within the system.",
  "Guest Details":"Here you can get the overview of the bookings related information",
  "Edit Property" :"Here user can Edit the details for the particular property.",
  "Add Policy" : "User can able to add new polices",
  "Edit Policy" : "User can able to Edit Policies and Delete Policies",
  "Property Management":"The Property Management section provides a comprehensive interface to manage various aspects of your property. You can add or update multiple settings, including Hotel Information, where you can edit details about the property, such as name, address, and contact information. You can also add tax information, configure Common Facilities, and manage the property's Gallery by uploading images. Additionally, you can adjust Time Settings, manage Audit Settings for tracking and reporting, handle OTA (Online Travel Agency) Management for integrations with third-party platforms, and update Payment Settings to ensure smooth financial transactions",
  "Policy Management":"Here user can view all the policies of the property selected",
  "Employee Management" :"The Employee Management section allows you to efficiently manage your staff members. You can add new employees by clicking the Add Employee button. Additionally, you can view detailed information about each employee . If needed, you also have the option to delete an employee from the system",
  "Subscription Management":"Here you can get the overview of guest related information.",

  "Amenities Report":"Amenities Reports section provides detailed insights into the amenities used by guests during their stay, allowing you to track which amenities were booked and their usage. This helps in understanding guest preferences and managing resource allocation. You can also add notes directly to the report by clicking the Notes button, enabling you to make quick updates or provide additional context about the usage of specific amenities.",
  "Room Status Report":"The Room Status report shows the current room status. Users can use filters to search for specific data.",
  "Add Room Type":"Users can add a new Room Type with the help of this page. Please provide the necessary information for each Room Type, including the room type, number of beds, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Edit Room Type":"Users can edit existing Room types using this page. Please provide the necessary information for each Room Type that you would like to change, including the Room Type, number of beds, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Base Rates":"Users can add a new Rate Plan with the help of this page. Please provide the necessary information for the rate plan, such as interval name, start date, end date, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Reservations":"Users can view the current booking status of guests with the help of this page.",
  "Activity":"Users can view the current booking revenue, total number of bookings, and other related information with the help of this page.",
  "Occupancy Status":"Users can view the occupancy status of rooms with the help of this page.",
  "Cancellation Policy":"Users can view the policy regarding the cancellation of booking.",
  "Cancellation":"This page provides an insight for the users who want to cancel their booking",
  "Daily Deposit Report":'The daily deposit report provides a comprehensive overview of all financial transactions and incoming revenue for the hotel, ensuring transparency and accountability',
  "Manage Roles":"Here, you can change an employee role, adjust their access to different properties by clicking the Change Access button, and also delete an employee if necessary. This feature helps streamline user management, ensuring that the right permissions and responsibilities are assigned to the appropriate individuals, improving overall operational efficiency and security",
  "Extend Stay":'The "extend stay" feature in a hotel management system enables guests to prolong their stay without the need for manual rebooking, streamlining the process for both guests and hotel staff.',
  "Extend Stay Process":"The Extend Stay Process refers to the procedure by which guests prolong their stay at a hotel beyond their original checkout date. This typically involves guests requesting an extension through the hotel's front desk or via the hotel management system.",
  "Registration Form":'The "Registration Form" feature in a hotel management system enables guests to view thier booking details , Personal Information regarding the stay in their hotel',
  "Payment Form":'The "Payment Form" feature in a hotel management system enables guests to view their booking details , Personal Information and Payment Details for Daily Charging View.',
  "Refund":'The "Refund" feature in a hotel management system enables employees to make refund for the payment initiated',
  "Assign Room":"The Assign Room feature in the PMS enables efficient management of housekeeping tasks. It includes functionalities to Add Housekeeper, Assign Specific Tasks, Edit Housekeeper Details, and Delete a Housekeeper seamlessly.",
  "Booking Engine":'A Booking Engine is an online system that allows customers to search, select, and book services such as hotel rooms',
  "Housekeeping Status Report":"A Housekeeping Status Report provides an overview of the current cleanliness and maintenance status of rooms in a property, such as a hotel. Here, you can check detailed information on various items like bathroom cleanliness and many more. This report helps ensure that all aspects of room cleanliness and maintenance are regularly checked and maintained, improving guest satisfaction and operational efficiency.",
  "Cleaning/Maintenance Report":"The Cleaning/Maintenance Report in the Property Management System (PMS) outlines routine checks and updates to ensure efficient operation. Here, you can see multiple details, including the Assigned Time to Housekeeper, Completion Date and Time, Housekeeper Name, Room, Room Type, Room Status, Assigned By, Housekeeper Status, and Guest Status. This feature helps track housekeeping tasks and ensures rooms are properly cleaned and ready for guests, enhancing operational efficiency and room management.",
  "Audit":"An Audit Report is a document that details the findings of an audit conducted by an independent auditor or internal audit team by clicking on Start Audit Button. You can perform a Cancel Audit by clicking the Cancel button, allowing you to revoke or undo the audit process if necessary.",
  "Status Update":"The Status Update feature allows you to update the status of rooms after housekeeping is complete. Once the cleaning is finished, you can change the room status to Available which will automatically update the housekeeper's status. In this section, you can view multiple details such as Housekeeper Details and Room details. This feature ensures accurate tracking and management of room readiness, helping streamline housekeeping operations and ensure rooms are properly prepared for guests",
  "Amenities Bookings":"Amenities Bookings section provides detailed insights into the amenities used by guests during their stay, allowing you to track which amenities were booked and their usage. This helps in understanding guest preferences and managing resource allocation. You can also add notes directly to the report by clicking the Notes button, enabling you to make quick updates or provide additional context about the usage of specific amenities.",
  "Booking Details":"The Booking Details page allows you to manage and update a guest's booking. You can edit the room rent, add extra items or services, apply adjustments (e.g., discounts or fees), and modify tax details. The deposit amount can be adjusted, and a complete transaction history shows payments, refunds, and changes. You can also view room rent history and tax history for any modifications made. If needed, use the room move feature to transfer the guest to a different room and apply any coupon codes for discounts. Additionally, you can view and edit the guest's contact information and booking history.",
  "Key Reports":"The Key Reports section allows you to view multiple reports Like Arrival Reports, In house Report, Checked out report, To be Checked Out Report and Booking Reports and perform various operations on bookings. In booking Reports we can search last 3 years booking data. You can Extend Stay, Cancel Stay, Change Reservation Date, Delete Reservation, Check-in guests, , and view booking Details and Notes. you can process payments by clicking the Pay button, from In house Report. Additionally, you can add Remarks by clicking the Add Remark tab, enabling you to update or add important information to specific bookings.",
  "Sell Inventory Report":"The Sell Inventory Report provides insights into the distribution of your property’s inventory, showing where and to whom the inventory has been sold. You can view details on sales to various parties, such as Guests, Property Own , and Strangers .",
  "Coupon Management":"The Coupon Management section allows you to efficiently manage and track your coupons. You can add new coupons by clicking on the Create New button, where you can specify details such as the coupon code, discount type, and applicable terms. To view the history of all issued or used coupons, simply click on the History tab. Additionally, you have the option to edit or delete existing coupons and also filters the coupon, giving you full control over your promotional offers."
};
export default InfoDetail;
