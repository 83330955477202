import React, { useState } from "react";
import "../../assets/css/coupons.css";
import couponve from "../../assets/images/Vectorcoupon.png";
// import copimage from "../../assets/images/rb_3649.png";
import copimage from "../../assets/images/Couponimg.png";
import Skeletonloader from "../../components/skeletonloader/Skeletonloader";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa6";
import noData from "../../assets/images/NoData.jpg";

const CouponGrid = (props) => {
  const [sortBy, setSortBy] = useState("Ascending"); // Default sort by date

  const [copiedCoupon, setCopiedCoupon] = useState(null);




  const formatDateRange = (from, to) => {
    const fromDate = moment(from).format("D");
    const toDate = moment(to).format("D MMM, YYYY");
    const sameMonth = moment(from).isSame(to, "month");

    return sameMonth
      ? `${fromDate} to ${toDate}` 
      : `${fromDate} ${moment(from).format("MMM")} to ${toDate}`;
  };

  const handleCopy = (code,index) => {
   
    navigator.clipboard.writeText(code);
    setCopiedCoupon(index); 

    setTimeout(() => {
      setCopiedCoupon(null); 
    }, 2000);
  };

  const handleSetActiveTab = (tab) => {
    props.setActiveTab(tab);
    if (tab === "create new") {
      props.setEditC(false);
    }
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  let filteredCoupons = props.coupon.filter((coupon) => {
    const titleMatch = props.filters.title
      ? coupon.title.toLowerCase().includes(props.filters.title.toLowerCase())
      : true;
    const categoryMatch = props.filters.category
      ? coupon.category === props.filters.category
      : true;
    const startDateMatch = props.filters.startDate
      ? new Date(coupon.valid_from) >= new Date(props.filters.startDate)
      : true;
    const endDateMatch = props.filters.endDate
      ? new Date(coupon.valid_to) <= new Date(props.filters.endDate)
      : true;

    return titleMatch && categoryMatch && startDateMatch && endDateMatch;
  });

  // Apply sorting based on selection
  console.log(filteredCoupons, "filteredCoupons");
  filteredCoupons = filteredCoupons.sort((a, b) => {
    if (sortBy === "asc") {
      return a.title.localeCompare(b.title);
    } else if (sortBy === "desc") {
      
      return b.title.localeCompare(a.title);
    }
    return 0;
  });

  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: 25 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            backgroundColor: "#f8f8f8",
          }}
        >
          <button
            onClick={() => props.setActiveTab("history")}
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #ccc",
              padding: "8px 16px",
              cursor: "pointer",
              borderRadius: 8,
            }}
          >
            History
          </button>

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              Sort By:
              <select
                value={sortBy}
                onChange={handleSortChange}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #ccc",
                  padding: "8px",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </label>

            {props?.couponpermission?.Coupon?.includes("store") && (
              <button
                id='create'
                onClick={() => handleSetActiveTab("create new")}
                style={{
                  backgroundColor: "#47ADFF",
                  color: "white",
                  border: "none",
                  padding: "8px 16px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                Create New
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="coupon-grid">
        {props.loading ? (
          <div style={{ display: "flex", width: "100%", gap: 25 }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeletonloader key={index} variant={"rectangular"} width={200} height={200} />
            ))}
          </div>
        ) :
        
        filteredCoupons.length > 0?
        (
          <>
            {filteredCoupons.length > 0 &&
              filteredCoupons
              .filter((coupon) => new Date(coupon.valid_to) >= new Date())
              .map((coupon, index) => (
                <div key={index} className="coupon-card">
                  <img
                    src={coupon.img || copimage}
                    onError={(e) => {
                      e.target.src = copimage; 
                    }}
                  />
                  <div className="coupontime">
                    <div className="coupontimes">
                      <img onError={copimage} src={couponve} />
                      <p>{formatDateRange(coupon.valid_from, coupon.valid_to)}</p>
                    </div>
                  </div>
                  <span style={{ display: "block", color: "grey", fontSize: "12px" , marginTop:"5px"}}>
                    <b>Available on :</b> {coupon.days.length < 7 ? coupon.days.map(day => day.slice(0, 2)).join(",") : "Everyday"}
                  </span>
                  <span style={{ display: "block", color: "grey", fontSize: "12px" }}>
                    <b>Category :</b> {coupon.category}
                  </span>

                  <div className="copy-coupon-container">
                    <span className="coupon-code">
                    <span>
                      <FaRegCopy className="copy-icon"  onClick={() => handleCopy(coupon.title, index)} />{" "}
                    </span>
                      <span className=""><b>{coupon.title}</b></span>
                    </span>

                    {copiedCoupon === index && (
                      <div className="tooltip" >
                        Copied!
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </>
        ):
        (<div >
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{width:'50%',height:'50%',borderRadius:'10px'}}>
              <img src={noData} alt="No Data Available"/>
            </div>
          </div>
        </div>)
      }
      </div>
    </div>
  );
};

export default CouponGrid;
