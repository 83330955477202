import React, { useState, useEffect } from "react";
import MinSec from "./MinSec";
import genpdf from "../../assets/images/genpdf.png";
import sendMsg from "../../assets/images/sendMsg.png";
import img from "../../assets/css/btn.module.css";
import Loading from "../../images/Loading";
import Skeletonloader from "../../components/skeletonloader/Skeletonloader";
import i from "../../assets/images/i.png"
import ImageTransition from "../../assets/icons/ImageTransition ";

function CommonBtn(props) {
  const { type, handleSendData, setPdfGen, sendMailLoading, disable, data } =
    props;
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);
  const [error, setError] = useState("");
  const [generate, setGenerate] = useState(false);
  const [timerA, setTimerA] = useState(1000);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const samep = () => {
    console.log("abcd...");
    setGenerate(false);
    handleSendData({ email: email });
    setPdfGen(false);
  };

  useEffect(async () => {
    if (data) {
      if (disable) {
        // await delay(set);
        document.getElementById("pdf_S").click();
      }
    }
  }, [disable]); // Dependency array includes activeButton to react on its changes

  const sendEmail = () => {
    if (!email) {
      setError("Please enter email");
    } else if (!valid) {
      setError("Please enter valid email address");
    } else {
      if (!disable) {
        if (data) {
          let time = (data.length / 853) * 60;
          let timeMsg;
          let minute;
          let second;
          if (Math.ceil(time) === 60) {
            timeMsg = "1 minute";
            minute = 1;
            second = 0;
          } else if (Math.ceil(time) < 60) {
            if (Math.ceil(time) === 1) {
              timeMsg = `${Math.ceil(time)} second`;
            } else {
              timeMsg = `${Math.ceil(time)} seconds`;
            }
            minute = 0;
            second = Math.ceil(time);
            // setMinute(0)
            // setSecond(Math.ceil(time))
          } else if (Math.ceil(time) > 60) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.ceil(time % 60);
            if (seconds === 0) {
              timeMsg = `${minutes} minutes`;
              minute = minutes;
              second = 0;
              // setMinute(minutes)
              // setSecond(0)
            } else if (second === 1) {
              timeMsg = `${minute} minutes and ${second} second`;
              minute = minutes;
              second = seconds;
              // setMinute(minutes)
              // setSecond(seconds)
            } else {
              timeMsg = `${minutes} minutes and ${seconds} seconds`;
              minute = minutes;
              second = seconds;
              // setMinute(minutes)
              // setSecond(seconds)
            }
          }
          let timeOut = 0;
          if (minute === 0) {
            timeOut = second * 1000;
            timeOut = timeOut + 2000;
          } else if (second === 0 && minute > 0) {
            timeOut = minute * 60 * 1000;
            timeOut = timeOut + 2000;
          } else {
            timeOut = minute * 60 * 1000 + second * 1000;
          }

          if (timeOut <= 4000) {
            timeOut += 1000;
          }

          let set = timeOut;
          console.log("timemmsg", timeMsg);
          console.log("time", timeOut);
          setTimerA(set);
        }

        setPdfGen(true);
        console.log("work start...here");
        setGenerate(true);
      } else {
        handleSendData({ email: email });
      }
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    const isValid = /\S+@\S+\.\S+/.test(inputEmail);
    setValid(isValid);
    if (inputEmail) {
      setError("");
    }
  };
  return (
    <div>
      {type === "pdf" ? (
        <>
          {data && data.length > 0 ? (
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <label className="required">Email </label>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control"
                  placeholder="Enter Your Email"
                  style={{ borderColor: valid ? "" : "red" }}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
              <div className="col-3 mt-4">
                <button
                  className="btn"
                  disabled={sendMailLoading || generate}
                  style={{
                    marginLeft: "5px",

                    display: generate ? "inlineBlock" : "",
                    fontWeight: "500",
                    alignItems: "center",
                    position: generate ? "relative" : "",
                    minWidth: sendMailLoading ? "160px" : "",
                    background: generate
                      ? "white"
                      : sendMailLoading
                      ? "white"
                      : "#1976D2",
                    borderRadius: "5px",
                    color: generate
                      ? "red"
                      : sendMailLoading
                      ? "#a2a2a2"
                      : "white",
                  }}
                  onClick={sendEmail}
                >
                  {generate ? (
                    <>
                      <img
                        style={{
                          width: "20px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        src={genpdf}
                        alt="genpdf"
                      />
                      <span style={{ marginRight: "2px" }}>Pdf Generate</span>
                      <div
                        style={{
                          animation: `${img.prixClipFix} ${timerA}ms linear forwards`,
                        }}
                        className={img.loader}
                      ></div>{" "}
                    </>
                  ) : (
                    <>
                      {sendMailLoading ? (
                        <>
                          Sending
                          <img className={img.imgM} src={sendMsg} />{" "}
                        </>
                      ) : (
                        <>Send</>
                      )}
                    </>
                  )}
                </button>
                {props.info && <img
                              src={i}
                              className="mx-2"
                              style={{ width: "24px" }}
                              title={props.info}
                              alt="info"
                            />}
                
                <button
                  style={{ display: "none" }}
                  id="pdf_S"
                  onClick={() => {
                    samep();
                  }}
                >
                  abc
                </button>
                {props.Rinfo && <ImageTransition info={props.Rinfo}/>}
              </div>
            </div>
          ) :<>
         {props.loading2 ?  <div className="row">
              <div className="col-3">
                <Skeletonloader width={'20%'} height={30} variant={'text'}/>
                <Skeletonloader width={'100%'} height={60} variant={'text'}/>
                </div>
                <div className="col-3">
                <Skeletonloader marginTop={30} width={'20%'} height={60} variant={'text'}/>
                </div>
             
                </div> :''}
          </>}
        </>
      ) : (
        <>
        
        </>
      )}
    </div>
  );
}

export default CommonBtn;
