import beforeImage from '../images/before-room.png'
import afterImage from '../images/after-room.png';

const ImageTransition = ({title, float=null}) => {
  return (
    <span
    title={title}
      style={{
        float: float || "none", // No !important
        width: "80px",
        height: "40px",
        marginTop:"5px",
        border: "2px solid #4CAF50",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <img src={beforeImage} alt="Before" style={{ width: "20px", marginLeft: "10px" }} />
      →
      <img src={afterImage} alt="After" style={{ width: "20px", marginRight: "10px" }} />
    </span>
  );
};

export default ImageTransition;
