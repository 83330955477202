import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import ApiCall from "../../Pages/Constants/ApiCall";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import booking from "../../assets/css/bookingen.module.css";
import defaultRoom from "../../assets/images/default_room.jpg";
import { Button } from "react-bootstrap";
import AlertDismissible from "../Common_Fun/AlertDismissible";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { FaWifi } from "react-icons/fa";
import { MdOutlineWifiOff } from "react-icons/md";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { TbAirConditioning } from "react-icons/tb";
import { MdOutlineTvOff } from "react-icons/md";
import { MdOutlineTv } from "react-icons/md";
import { MdPowerOff } from "react-icons/md";
import { MdPower } from "react-icons/md";
import { CgUnavailable } from "react-icons/cg";
import {  Tooltip, OverlayTrigger } from 'react-bootstrap';
import PersonIcon from '@mui/icons-material/Person';
// import html2canvas from "html2canvas";
import defaultRoomImage from "../../assets/images/Image (17).png"
import FacilityModelComponent from "../../common/FacilityModelComponent";
import { countAvailableFacilities } from "../Constants/facilitylength";

function ReserveFront(props) {
  const [mainImg, UseMainImg] = useState("");
  const [plusMinus, UsePlusMinus] = useState({});
  const [number, SetNumber] = useState({});
  const [totalRoom, SetTotalRoom] = useState({});

  const [maxOccupancy, setMaxOccupancy] = useState({});
  const [defaultImg, setDefaultImg] = useState({});
  const [load, setLoad] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 430);
  const [isFacmodel,setIsFacModel] = useState(false)
  const [facData,setFacilityData] = useState({})
  const [isRoomTypeMore,setIsRoomTypeMore] = useState(false)

  let ase = true;

  const roomCount = {};
  // const totalRoom = {}

  const roomtypes = [];

  const handleResize = () => {
    setIsMobile(window.innerWidth < 430);
};
useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => {
      window.removeEventListener('resize', handleResize);
  };
}, []);

  const handleThumbnailClick = (event) => {
    const numberId = event.target.getAttribute("data-id");
    const thumbnailImageSrc = event.currentTarget.getAttribute("src");

    UseMainImg({ [numberId]: thumbnailImageSrc });
  };

  useEffect(() => {
    props.roomType.map((roomtype) => {
      totalRoom[roomtype.room_all_type] = 0;
      maxOccupancy[roomtype.room_all_type] = [
        parseInt(roomtype.max_adult),
        parseInt(roomtype.max_child),
      ];
    });

    props.data.map((room) => {
      totalRoom[room.roomType.room_all_type] =
        totalRoom[room.roomType.room_all_type] + 1;
    });
    setLoad(true);
  }, []);

 
  console.log(props,'datach');
  const matchedCoupons = Array.isArray(props.couponDiscount)
  ? props.couponDiscount.filter(cd =>
      cd.name === props.selectedCoupon.title &&
      props.selectedCoupon.roomType.some(rt => rt.room_all_type === cd.roomType)
    )
  : [];



  const matchedCouponEng = Array.isArray(props.couponDiscountEng) && props.selectedCouponEng
    ? props.couponDiscountEng.filter(cd =>
        cd.name === props.selectedCouponEng.title &&
        props.selectedCouponEng.roomType.some(rt => rt.room_all_type === cd.roomType)
      )
    : [];

  console.log(matchedCouponEng, "filtered results");
  
  console.log(matchedCoupons,'matchedCoupons');
  
  const Add = (roomType) => {
    let value;
    let dict = number;
    console.log(number, roomType);
    let sumDict = 0;
    Object.keys(number).map((key) => {
      sumDict += parseInt(number[key]);
    });
    if (props.limit !== undefined && props.limit <= sumDict) {
      let msg = `Guests are only allowed to choose a maximum of ${props.limit} rooms.`;
      props.warning(msg);
    } else if (totalRoom[roomType] > number[roomType]) {
      value = number[roomType] + 1;
      SetNumber((newValue) => ({ ...newValue, [roomType]: value }));
      dict[roomType] = value;
    } else {
      let msg = `Maximum of ${number[roomType]}  ${
        number[roomType] > 1 ? "rooms" : "room"
      } available in ${roomType}`;
      setIsRoomTypeMore(roomType)
      props.warning(msg);
    }

    totalNum(dict);
    if (props.cancel !== undefined) {
      props.cancel(true);
    }
  };

  const Change = (roomType, enterVal) => {
    let value;
    let dict = number;
    let sumDict = 0;
    Object.keys(number).map((key) => {
      sumDict += parseInt(number[key]);
    });

    let strV = String(enterVal);
    if (strV.length > 1 && strV[0] === "0") {
      strV = strV.slice(1);
      value = parseInt(strV);
    } else {
      value = parseInt(strV);
    }
    if (props.limit !== undefined && props.limit < sumDict + value) {
      let msg = `Guests are only allowed to choose a maximum of ${props.limit} rooms.`;
      props.warning(msg);
      value = 0;
    } else if (totalRoom[roomType] < parseInt(enterVal)) {
      value = totalRoom[roomType];
      let msg = `Max ${totalRoom[roomType]} available in ${roomType}`;
      props.warning(msg);
    } else if (parseInt(enterVal) < 0) {
      value = 0;
    } else if (!isNaN(enterVal)) {
      value = Math.floor(parseFloat(enterVal));
    }

    SetNumber((newValue) => ({ ...newValue, [roomType]: value }));
    dict[roomType] = value;

    if (enterVal === "") {
      SetNumber((newValue) => ({ ...newValue, [roomType]: 0 }));
      dict[roomType] = 0;
    }

    totalNum(dict);
    if (props.cancel !== undefined) {
      props.cancel(true);
    }
  };

  const Sub = (id) => {
    props.warning("")
    let value;
    let dict = number;
    if (number[id] > 0) {
      value = number[id] - 1;
      dict[id] = value;
      SetNumber((newValue) => ({ ...newValue, [id]: value }));
    }

    totalNum(dict);
    if (props.cancel !== undefined) {
      props.cancel(true);
    }
  };

  const totalNum = (newNum = null) => {
    let num = 0;
    let dict = {};

    Object.keys(newNum).map((value) => {
      num += parseInt(number[value]);

      if (number[value] === 0) {
        delete newNum[value];
      }

      if (value in newNum)
        dict[value] = roomCount[value].slice(0, number[value]);
    });

    const defImg = {};
    Object.keys(dict).forEach((element) => {
      defImg[element] = defaultImg[element];
    });

    // props.data[]
    props.total(num, dict, defImg);
  };

  const AddRoom = (id, roomType) => {
    let dict = number;
    let sumDict = 0;
    Object.keys(number).map((key) => {
      sumDict += parseInt(number[key]);
    });
    if (props.limit !== undefined && props.limit <= sumDict) {
      let msg = `Guests are only allowed to choose a maximum of ${props.limit} rooms.`;
      props.warning(msg);
    } else {
      UsePlusMinus((prevPlusMinus) => ({ ...prevPlusMinus, [id]: true }));
      SetNumber((newValue) => ({ ...newValue, [roomType]: 1 }));
      dict[roomType] = 1;

      totalNum(dict);
    }
    if (props.cancel !== undefined) {
      props.cancel(true);
    }
  };

  const Cancel = (id, type) => {
    props.warning("")
    let dict = number;
    UsePlusMinus((prevPlusMinus) => ({ ...prevPlusMinus, [id]: false }));
    SetNumber((newValue) => ({ ...newValue, [type]: 0 }));
    dict[type] = 0;

    totalNum(dict);
    if (props.cancel !== undefined) {
      props.cancel(true);
    }
  };

  const sortedAse = [...props.data].sort((roomA, roomB) => {
    if (props.low) {
      return roomA.roomType.room_rate - roomB.roomType.room_rate; // Sort in ascending order
    } else {
      return roomB.roomType.room_rate - roomA.roomType.room_rate; // Sort in descending order
    }
  });
  const tooltipContent = (
    <Tooltip id="tooltip-content" className="vertical-tooltip">
      <div className="vertical-word">Free Parking</div>
      <div className="vertical-word">Room Service</div>
      <div className="vertical-word">Bathtub</div>
    </Tooltip>
  );
  // let ShortData = props.low ? sortedAse:sortedDse
  const FacModel=(room)=>{
    setIsFacModel(true)
    setFacilityData(room)
    
  }
  console.log(sortedAse,'soreted')
  return (
    load && (
      <>
        {sortedAse.map((room) => {
          const facilityDict =
            room?.roomType?.facilitys !== ""
              ? ""
              : "";
          console.log(facilityDict,room,'checkfac');
          const isRoomTypeShown = roomtypes.includes(
            room.roomType.room_all_type
          );

          roomtypes.push(room.roomType.room_all_type);

          if (isRoomTypeShown) {
            if (!roomCount[room.roomType.room_all_type]) {
              roomCount[room.roomType.room_all_type] = [];
            }
            roomCount[room.roomType.room_all_type].push(room.number);
          } else {
            if (!roomCount[room.roomType.room_all_type]) {
              roomCount[room.roomType.room_all_type] = [];
            }
            roomCount[room.roomType.room_all_type].push(room.number);
          }

          if (
            room?.roomType.image !== null &&
            room?.roomType.image !== undefined &&
            room?.roomType.image !== ""
          ) {
            if (!(room.roomType.room_all_type in defaultImg)) {
              defaultImg[room.roomType.room_all_type] =
                room.image !== null
                  ? room.roomType?.image.split("?")[0]
                  : defaultRoom;
            }
          } else {
            if (!(room.roomType.room_all_type in defaultImg)) {
              defaultImg[room.roomType.room_all_type] = defaultRoom;
            }
          }
          

          
          

          return (
            !isRoomTypeShown && (
              <Row className={booking.rowBookingEngine}>
                {isFacmodel && <FacilityModelComponent isFacmodel={isFacmodel} facData={facData} setIsFacModel={setIsFacModel}/>}
                <Col id={room.number} xs={12} md={12} lg={7} >
                  <Row id="image-part" className={booking.bookingenginerep}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={9}>
                      <div className={booking.mainImage}>
                        <img
                          style={{
                            width: isMobile?'100%':"540px",
                            height: isMobile?'auto':"380px",
                            objectFit: "cover", 
                            borderRadius: "6px",
                          }}
                          className="mt-3"
                          src={
                            mainImg[room.number] === "" ||
                            mainImg[room.number] === undefined
                              ? room.roomType.image !== null
                                ? room.roomType.image.split("?")[0]
                                : defaultRoom
                              : mainImg[room.number]
                          }
                          onError={(e) => {
                            e.target.src = defaultRoom; // Replace with your custom image path
                          }}
                          alt="Main Image"
                        />
                      </div>
                    </Col>
                   
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={3} className="thumbnail">
                      <Card className={booking.card} style={{ border: "none" }}>
                        <div className={booking.cardnewname}>
                          
                         <img
                            style={{
                              width: "100px",
                              height: "105px",
                              objectFit: "cover",
                              borderRadius: "6px",
                              transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            className={`${booking.hovereffect} mt-4 `}
                            data-id={room.number}
                            src={
                              room.roomType.image !== null
                                ? room.roomType.image.split("?")[0]
                                : defaultRoom
                            }
                            onClick={handleThumbnailClick}
                            alt="Thumbnail 1"
                            onError={(e) => {
                              e.target.src = defaultRoom; // Replace with your custom image path
                            }}
                          /> 
                          <img
                            style={{
                              width: "100px",
                              height: "105px",
                              objectFit: "cover", 
                              borderRadius: "6px",
                              transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            className={`${booking.hovereffect} mt-4`}
                            data-id={room.number}
                            src={
                              room.roomType.room_image_1 !== null &&
                              room.roomType.room_image_1.split("?")[0] || {defaultRoom}
                            }
                            onClick={handleThumbnailClick}
                            alt="Thumbnail 2"
                            onError={(e) => {
                              e.target.src = defaultRoom; // Replace with your custom image path
                            }}
                          />
                          <img
                            style={{
                              width: "100px",
                              height: "105px",
                              objectFit: "cover", 
                              borderRadius: "6px",
                              transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            className={`${booking.hovereffect} mt-4`}
                            data-id={room.number}
                            src={
                              room.roomType.room_image_2 !== null &&
                              room.roomType.room_image_2.split("?")[0]
                            }
                            onClick={handleThumbnailClick}
                            alt="Thumbnail 3"
                            onError={(e) => {
                              e.target.src = defaultRoom; // Replace with your custom image path
                            }}
                          />

                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={12}
                  md={12}
                  lg={5}
                 
                  style={{ marginLeft:!isMobile ? -20 : 0 , display:"flex"}}
                  className={booking.mobileSpacing}
                >
                  <Card
                    className={booking.card}
                    style={{ marginTop: "4%", border: "none" }}
                  >
                    <Card.Header className={booking["card-header"]}>
                      {room.roomType.room_all_type}
                      <div
                       title={`Max Occupancy`}
                        className={booking.occupancy}
                        style={{
                          float: "right",
                          color: "#FF0000",
                          fontWeight: 600,
                          
                        }}
                      >
                        
                        <PersonIcon style={{height:15,width:15,marginTop:-2}}/>  {maxOccupancy[room.roomType.room_all_type][0]} Adults • {maxOccupancy[room.roomType.room_all_type][1]} Children{" "}
                     
                      </div>
                    </Card.Header>
                    <Card.Body className={booking["card-body"]}>
  <div style={{ position: "relative", overflow: "visible" }}>
    <ReactReadMoreReadLess
      charLimit={240}
      readMoreText={"read more"}
      readLessText={"read less"}
      readMoreStyle={{
        color: "#5dc1f2",
        cursor: "pointer",
        zIndex: 10
      }}
      readLessStyle={{
        color: "#5dc1f2",
        cursor: "pointer",
        zIndex: 10
      }}
      style={{
        color: "#5F5F5F",
        fontSize: "14px",
        fontWeight: 400,
      }}
    >
      {room?.roomType?.description}
    </ReactReadMoreReadLess>
  </div>
</Card.Body>

                    <Card.Footer className={`${booking["card-footer"]} mt-1`} style={{height:isMobile?"":"100px", alignContent:"center"}}>
                      <Row
                         className={booking.twoRows}
                        id={facilityDict != null && facilityDict["Sofa Bed"]}
                        style={{ fontSize: "8px" }}
                      >
                        {facilityDict == null && (
                          <Col
                          className={booking.customCol}
                            style={{
                              color: "grey",
                              fontWeight: 400,
                              fontSize: "16px",
                              width: "100px",
                              justifyContent:"center",
                              alignContent:"center"
                            }}
                          >
                            <CgUnavailable /> Facilities Unavailable!
                          </Col>
                        )}
                        {facilityDict != null && (
                          <Row className="facility-row">
                            {/* First Row */}
                            <Col
                              md={3}
                              lg={6}
                              xl={3}
                              className="customCol mt-4"
                              style={{
                                color: "grey",
                                fontWeight: 400,
                                fontSize: "16px",
                              }}
                            >
                              {facilityDict["Air Conditioning"] === "Yes" ? (
                                <div style={{display:'flex', gap:5, flexDirection:'row', justifyContent:'center'}}>
                                  <TbAirConditioning style={{ fontSize: "25px" }} /> 
                                  <span>AC</span>
                                </div>
                              ) : (
                                <div style={{display:'flex', gap:5, flexDirection:'row',justifyContent:'center'}}>
                                  <TbAirConditioningDisabled style={{ fontSize: "25px" }} /> 
                                  <span>AC</span>
                                </div>
                              )}
                            </Col>

                            <Col
                              md={3}
                              lg={6}
                              xl={3}
                              className="customCol mt-4"
                              style={{
                                color: "grey",
                                fontWeight: 400,
                                fontSize: "16px",
                              }}
                            >
                              {facilityDict["Free WIFI"] === "Yes" ? (
                                <div style={{display:'flex', gap:5, flexDirection:'row',justifyContent:'center'}}>
                                  <FaWifi style={{ fontSize: "25px" }} /> 
                                  <span>Wifi</span>
                                </div>
                              ) : (
                                <div style={{display:'flex', gap:5, flexDirection:'row',justifyContent:'center'}}>
                                  <MdOutlineWifiOff style={{ fontSize: "25px" }} />
                                  <span> Wifi</span>
                                </div>
                              )}
                            </Col>

                            {/* Second Row */}
                            <Col
                              md={3}
                              lg={6}
                              xl={3}
                              className="customCol mt-4"
                              style={{
                                color: "grey",
                                fontWeight: 400,
                                fontSize: "16px",
                              }}
                            >
                              {facilityDict["TV"] === "Yes" ? (
                                <div style={{display:'flex', gap:5, flexDirection:'row',justifyContent:'center'}}>
                                  <MdOutlineTv style={{ fontSize: "25px" }} /> 
                                  <span>TV</span>
                                </div>
                              ) : (
                                <div  style={{display:'flex', gap:5, flexDirection:'row',justifyContent:'center'}}>
                                  <MdOutlineTvOff style={{ fontSize: "25px" }} /> 
                                  <span>TV</span>
                                </div>
                              )}
                            </Col>

                            <Col
                              md={3}
                              lg={6}
                              xl={3}
                              className="customCol mt-4"
                              style={{
                                color: "grey",
                                fontWeight: 400,
                                fontSize: "16px",
                              }}
                            >
                              
                                <span style={{color:"#1976D2" ,cursor:"pointer",fontWeight:"bold"}}  onClick={()=>FacModel(room)}>+ {countAvailableFacilities(room?.roomType?.facilitys)} More</span>
                            
                            </Col>
                          </Row>
                        )}

                      </Row>
                    </Card.Footer>

                    <Card.Footer
                      className={`${booking["card-footer"]} mt-4`}
                      style={{
                        position: "relative",
                        bottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Row
                        className={`${booking["row3"]} mt-4`}
                        style={{ position: "sticky", bottom: 0}}
                      >
                        <Col xs={6} lg={5} className={booking.price} style={{paddingLeft:"5px"}}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          > 
                          {(props.reservation && !props.owner) && (
                            <>
                              <span
                                style={{
                                  textDecoration: matchedCoupons.some((coupon) => {
                                    const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                    return (
                                      coupon.roomType === room.roomType.room_all_type &&
                                      ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                        (coupon.discount > 0 && sumTotal > 0))
                                    );
                                  })
                                    ? "line-through"
                                    : "none",
                                  fontSize: matchedCoupons.some((coupon) => {
                                    const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                    return (
                                      coupon.roomType === room.roomType.room_all_type &&
                                      ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                        (coupon.discount > 0 && sumTotal > 0))
                                    );
                                  })
                                    ? "16px"
                                    : "20px",
                                  color: matchedCoupons.some((coupon) => {
                                    const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                    return (
                                      coupon.roomType === room.roomType.room_all_type &&
                                      ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                        (coupon.discount > 0 && sumTotal > 0))
                                    );
                                  })
                                    ? "grey"
                                    : "red",
                                }}
                              >
                                ${props.sumTotal[room.roomType.room_all_type]}
                              </span>

                              {matchedCoupons.some((coupon) => {
                                const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                return (
                                  coupon.roomType === room.roomType.room_all_type &&
                                  ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                    (coupon.discount > 0 && sumTotal > 0))
                                );
                              }) && (
                                <span>
                                  
                                  {matchedCoupons.map((coupon) => {

                              if (coupon.roomType === room.roomType.room_all_type) {
                                return (
                                  <span key={coupon.id}>
                                    ${coupon.final_amt} {/* Display final_amt */}
                                  </span>
                                  );
                                }
                                return null;
                              })}
                                </span>
                              )}
                            </>
                          )}

                          { props.owner && (
                             <>
                             <span
                               style={{
                                 textDecoration: matchedCouponEng.some((coupon) => {
                                   const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                   return (
                                     coupon.roomType === room.roomType.room_all_type &&
                                     ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                       (coupon.discount > 0 && sumTotal > 0))
                                   );
                                 })
                                   ? "line-through"
                                   : "none",
                                   fontSize: matchedCoupons.some((coupon) => {
                                    const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                    return (
                                      coupon.roomType === room.roomType.room_all_type &&
                                      ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                        (coupon.discount > 0 && sumTotal > 0))
                                    );
                                  })
                                    ? "16px"
                                    : "20px",
                                 color: matchedCouponEng.some((coupon) => {
                                   const sumTotal = props.sumTotal[room.roomType.room_all_type]; // Correctly referencing sumTotal
                                   return (
                                     coupon.roomType === room.roomType.room_all_type &&
                                     ((coupon.discount === null && sumTotal > coupon.apply_discount) ||
                                       (coupon.discount > 0 && sumTotal > 0))
                                   );
                                 })
                                   ? "grey"
                                   : "red",
                               }}
                             >
                               ${props.sumTotal[room.roomType.room_all_type]}
                             </span>

                             {matchedCouponEng.map((coupon) => {

                              if (coupon.roomType === room.roomType.room_all_type) {
                                return (
                                  <span key={coupon.id}>
                                    ${coupon.final_amt} {/* Display final_amt */}
                                  </span>
                                );
                              }
                              return null;
                            })}
                           </>
                          )}



                          {!props.reservation && !props.owner && <>${props.sumTotal[room.roomType.room_all_type]}</>}
                          </div>
                          <p className={booking.pMute}>
                            Estimate cost per room for entire stay.
                            {props.editable !== undefined ? (
                              <Button
                                style={{
                                  width: "max-content",
                                  fontSize: "small",
                                }}
                                onClick={() => {
                                  props.SendButton(room.roomType.room_all_type);
                                }}
                                className={booking["price"]}
                                variant="link"
                              >
                                Select Price
                              </Button>
                            ) : (
                              <> </>
                            )}
                          </p>
                        </Col>
                        <Col xs={6} lg={7}>
                          <Row  className={booking.responsiveRow}>
                            <Col xs={6} lg={6} className={`pt-4 p-1 ${booking.colButton}`}>
                              {!plusMinus[room.number] && (
                                <Link
                                  state={{ movie: "sss" }}
                                  type="button"
                                  to={
                                    props.propertyName !== undefined
                                      ? `/RoomViewDetail/${props.owner}/${props.propertyName}/${room.roomType.room_all_type}`
                                      : `/Room_view/${room.number_id}`
                                  }
                                  rel="noopener noreferrer"
                                  className={booking.button}
                                >
                                  View Details
                                </Link>
                              )}
                              {plusMinus[room.number] && (
                                <div
                                  style={{ height:'35px',width:'fit-content', border:"2px solid grey", borderRadius:'5px'  }}
                                  className={`${booking.plusminus} d-flex justify-content-center align-items-center`}
                                >
                                  <button
                                    className={`${booking.side} align-items-center minus-disable`}
                                    onClick={() => {
                                      Sub(room.roomType.room_all_type);
                                    }}
                                    disabled={number[room.roomType.room_all_type] <= 1}
                                    style={{ width: "32px", borderRadius: "0px", border:'none', backgroundColor:'none' }}
                                  >
                                    -
                                  </button>
                                  <Form.Control
                                    className={booking.input}
                                    type="number"
                                    step="1"
                                    value={number[room.roomType.room_all_type]}
                                    onChange={(e) => {
                                      Change(
                                        room.roomType.room_all_type,
                                        e.target.value
                                      );
                                    }}
                                    style={{ width: "40px", borderRadius: "0px", border:'none' }}
                                  />
                                  <button
                                    className={`${booking.side} align-items-center`}
                                    id={room.roomType.room_all_type}
                                    onClick={() => {
                                      Add(room.roomType.room_all_type);
                                    }}
                                    style={{ width: "32px", borderRadius: "0px", border:'none' }}
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            </Col>
                      
                            <Col xs={6} lg={6} className={`pt-4 p-1 ${booking.colButton}`}>
                              <Button
                                id='addingnewroom'
                                variant=""
                                onClick={() => {
                                  !plusMinus[room.number]
                                    ? AddRoom(
                                        room.number,
                                        room.roomType.room_all_type,
                                        room?.image?.split("?")[0]
                                      )
                                    : Cancel(
                                        room.number,
                                        room.roomType.room_all_type
                                      );
                                }}
                                style={{
                                  backgroundColor: !plusMinus[room.number]
                                    ? "#1AB64F"
                                    : "#EA4747",
                                }}
                                className={booking.add}
                              >
                                {!plusMinus[room.number] ? (
                                  <> Add Room </>
                                ) : (
                                  <>Cancel</>
                                )}
                              </Button>
                              
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                      {isRoomTypeMore !==false && <><small style={{color:"red",fontSize:13,marginLeft:-4,fontWeight:"bold",fontFamily:"inter"}}>{isRoomTypeMore === room.roomType.room_all_type ? props.warningMsg:""}</small></>}
                    </Card.Footer>
                  </Card>
                </Col>
                <hr
                  className={booking.hr}
                  style={{
                    width: "98%",
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                />
              </Row>
            )
          );
        })}
      </>
    )
  );
}

export default ReserveFront;
